import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import { useHistory } from "react-router-dom";
import { gecenGun, kalanGun } from "../helpers/generic";

import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  ListGroup,
  Spinner,
  Badge,
  InputGroup,
  ButtonToolbar,
  ButtonGroup,
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { DownloadTableExcel } from "react-export-table-to-excel";

function BookmarkCheckFill() {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className="bi bi-bookmark-check-fill bookmark-check-fill float-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
        />
      </svg>
    </span>
  );
}

function Egitim() {
  const history = useHistory();
  const [egitimId, setegitimId] = React.useState(0);
  const [guncelle, setGuncelle] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [anketExcelData, setanketExcelData] = React.useState([]);
  const [form, setForm] = React.useState({ MailSmsGonder: true });
  const [aktifEgitim, setaktifEgitim] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [showAnketModal, setshowAnketModal] = React.useState(false);
  const [showAnketModalExcel, setshowAnketModalExcel] = React.useState(false);
  const [personelData, setpersonelData] = React.useState([]);
  const [atananPersonel, setatananPersonel] = React.useState([]);
  const [anketSorulari, setanketSorulari] = React.useState([]);
  const [showPersonelModal, setshowPersonelModal] = React.useState(false);

  const tableRef = useRef(null);

  const token = secureLocalStorage.getItem("token");
  const programBaslamaCheck = () => {
    return 0;
    //
    if (aktifEgitim.BasTarih < new Date().toISOString()) {
      alert(
        "Program başladıktan sonra personel listesinde değişiklik yapılamaz! Başlangıç Tarihi: " +
          aktifEgitim.BasTarih
      );
      return 1;
    }
    return 0;
  };
  const egitimePersonelEkle = (elem) => {
    if (programBaslamaCheck() == 1) return;
    elem.mail = "1";
    let tmp = structuredClone(atananPersonel);
    let res = tmp.filter((C) => C.k_no == elem.k_no);
    if (res.length > 0) return;
    tmp.push(elem);

    setatananPersonel(tmp);

    // let tmp2 = structuredClone(personelData);
    // for (let index = 0; index < tmp2.length; index++) {
    //   const element = tmp2[index];
    //   if (elem.k_no === element.k_no) {
    //     tmp2.splice(index, 1);
    //   }
    // }
    // setpersonelData(tmp2);
  };
  const egitimePersonelCikart = (elem) => {
    let tmp = structuredClone(atananPersonel);
    for (let index = 0; index < tmp.length; index++) {
      const element = tmp[index];
      if (elem.k_no === element.k_no) {
        tmp.splice(index, 1);
      }
    }
    setatananPersonel(tmp);
    // let tmp2 = structuredClone(personelData);
    // tmp2.push(elem)
    // setpersonelData(tmp2);
  };
  const egitimePersonelKatildi = (elem) => {
    if (
      confirm(
        "Eğitime katıldı durumuna değiştirmek istediğinizden emin misiniz?"
      )
    ) {
      (async () => {
        const dt = { Id: elem.Id, katildi: 1, tad: "EgitimeKatildi" };
        const istek = await fetch(variables.API_URL + "Page", {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Tad: "EgitimeKatildi",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dt),
        });
        if (await istek.ok) {
          getEgitimeKatilanPersonel(elem.EgitimId);
          notify("tc", "Personel Eğitime Katıldı!", "success");
        } else {
          let txt = await istek.text();
          alert(
            txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt
          );
        }
      })();
    }
  };
  const egitimePersonelKatildiTamamina = (elem) => {
    if (
      confirm(
        "'Eğitimin tamamına katıldı' durumuna değiştirmek istediğinizden emin misiniz?"
      )
    ) {
      (async () => {
        const dt = {
          Id: elem.Id,
          tamaminaKatildi: 1,
          tad: "EgitiminTamaminaKatildi",
        };
        const istek = await fetch(variables.API_URL + "Page", {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Tad: "EgitiminTamaminaKatildi",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dt),
        });
        if (await istek.ok) {
          getEgitimeKatilanPersonel(elem.EgitimId);
          notify("tc", "Personel Eğitimin Tamamına Katıldı!", "success");
        } else {
          let txt = await istek.text();
          alert(
            txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt
          );
        }
      })();
    }
  };
  const egitimePersonelKatilmadi = (elem) => {
    if (
      confirm(
        "Eğitime katılmadı durumuna değiştirmek istediğinizden emin misiniz?"
      )
    ) {
      (async () => {
        const dt = { Id: elem.Id, katildi: 0, tad: "EgitimeKatildi" };
        const istek = await fetch(variables.API_URL + "Page", {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Tad: "EgitimeKatildi",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dt),
        });
        if (await istek.ok) {
          getEgitimeKatilanPersonel(elem.EgitimId);
          notify("tc", "Personel Eğitime Katılmadı!", "success");
        } else {
          let txt = await istek.text();
          alert(
            txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt
          );
        }
      })();
    }
  };
  const egitimePersonelKatilmadiTamamina = (elem) => {
    if (
      confirm(
        "'Eğitimin tamamına katılmadı' durumuna değiştirmek istediğinizden emin misiniz?"
      )
    ) {
      (async () => {
        const dt = {
          Id: elem.Id,
          tamaminaKatildi: 0,
          tad: "EgitiminTamaminaKatildi",
        };
        const istek = await fetch(variables.API_URL + "Page", {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Tad: "EgitiminTamaminaKatildi",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dt),
        });
        if (await istek.ok) {
          getEgitimeKatilanPersonel(elem.EgitimId);
          notify("tc", "Personel Eğitimin Tamamına Katılmadı!", "success");
        } else {
          let txt = await istek.text();
          alert(
            txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt
          );
        }
      })();
    }
  };
  const mailGonderme = (elem: any) => {
    return;
    // let tmp = structuredClone(atananPersonel);
    // for (let index = 0; index < tmp.length; index++) {
    //     const element = tmp[index];
    //     if (elem.k_no === element.k_no) {
    //         if (element.mail == "1") {
    //             element.mail = "0";
    //         } else {
    //             element.mail = "1";
    //         }
    //     }
    // }
    // setatananPersonel(tmp);
  };
  const notificationAlertRef = React.useRef(null);
  const notify = (place, textt, typee) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{textt}</div>
        </div>
      ),
      type: typee ?? type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getData = async () => {
    const istek = await fetch(variables.API_URL + "egitim", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (istek.ok) {
      setData(await istek.json());
    } else {
      let txt = await istek.text();
      alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      history.push("/admin/giris");
    }
  };
  const getPersonelData = () => {
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "personeller", {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          setpersonelData(json);
        })
        .catch((err) => console.log(err));
    })();
  };
//   const istek = await fetch(variables.API_URL + "Page", {
// 	method: "post",
// 	headers: {
// 	  Accept: "application/json, text/plain, */*",
// 	  "Content-Type": "application/json",
// 	  Tad: "AmirAnketlerGet",
// 	  Authorization: "Bearer " + token,
// 	},
// 	body: JSON.stringify(dt),
//   });
  const getAnketSonucByEgitimId = (elem) => {
	var dt = {};
    dt.tad = "AnketSonucByEgitimId"; 
    dt.egitimid = elem?.Id; 
	console.log(dt);
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*","Content-Type": "application/json",
          Tad: "AnketSonucByEgitimId",
          Authorization: "Bearer " + token,
        },
		body: JSON.stringify(dt),
      })
        .then((res) => res.json())
        .then((json) => {
			setanketExcelData(json);
        })
        .catch((err) => console.log(err));
    })();
  };
  const getEgitimeKatilanPersonel = (egitimId) => {
    (async () => {
      const rawResponse = await fetch(
        variables.API_URL + "egitim/EgitimeKatilanlar/" + egitimId,
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => setatananPersonel(json))
        .catch((err) => console.log(err));
    })();
  };
  const addFormData = (key, value) => {
    const ff = structuredClone(form);
    ff[key] = value;
    setForm(ff);
  };
  const duzenle = (elem) => {
    setaktifEgitim(elem);
    setGuncelle(true);
    setShowModal(true);
    setForm(elem);
  };
  const personelModalAc = (elem) => {
    setaktifEgitim(elem);
    getPersonelData();
    getEgitimeKatilanPersonel(elem.Id);
    setshowPersonelModal(true);
    //setGuncelle(true);
    setForm(elem);
  };
  const getAnketData = (elem) => {
    //const dt = structuredClone(detayRow);
    var dt = {};
    dt.tad = "AnketlerGet";
    dt.anket = "EgitimSonrasiAnketPersonel";
    dt.EgitimId = elem?.EgitimId;
    dt.k_no = elem?.k_no;
    (async () => {
      const istek = await fetch(variables.API_URL + "Page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: "AnketlerGet",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dt),
      });
      if (await istek.ok) {
        //console.log(await istek?.json());
        //setDetay(await istek?.json());
        setanketSorulari(await istek?.json());
        //talepDetayGetir(form.Id);
        //notify("tc", "Kayıt işlemi başarılı", "success");
      } else {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      }
    })();
  };
  const getAnketDataAmir = (elem) => {
    //const dt = structuredClone(detayRow);
    var dt = {};
    dt.tad = "AmirAnketlerGet";
    dt.anket = "EgitimSonrasiAnketAmir";
    dt.EgitimId = elem?.EgitimId;
    dt.k_no = elem?.k_no;
    (async () => {
      const istek = await fetch(variables.API_URL + "Page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: "AmirAnketlerGet",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dt),
      });
      if (await istek.ok) {
        //console.log(await istek?.json());
        //setDetay(await istek?.json());
        setanketSorulari(await istek?.json());
        //talepDetayGetir(form.Id);
        //notify("tc", "Kayıt işlemi başarılı", "success");
      } else {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      }
    })();
  };
  //anketModalExcelAc(elem)}
  function anketModalExcelAc(elem: never): void {
	console.log(elem)
    getAnketSonucByEgitimId(elem)
	setshowAnketModalExcel(true);
    return;
  }
  function anketModalAc(elem: never): void {
    getPersonelData();
    getEgitimeKatilanPersonel(elem.Id);
    setshowAnketModal(true);
    setForm(elem);
    return;
  }
  function personelModalKapat() {
    setshowPersonelModal(false);
    setForm({});
    setatananPersonel([]);
    setaktifEgitim({});
    // setGuncelle(false);
  }
  function anketModalKapat() {
    setshowAnketModal(false);
    setForm({});
    setanketSorulari([]);
    setatananPersonel([]);
    // setGuncelle(false);
  }
  function modalKapat() {
    setaktifEgitim({});
    setShowModal(false);
    setGuncelle(false);
    setForm({});
  }
  const formKaydet = () => {
    //console.log(form, "form");
    if (form?.Program == "Seçiniz") {
      alert("Program seçiniz!!!");
      return;
    }
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "egitim", {
        method: "POST",
        // headers: { 'Content-Type': 'application/json' },
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(form),
        //body: form
      });
      const content = await rawResponse.json();
      // console.log(content);
      modalKapat();
      getData();
      notify("tc", "Kayıt işlemi başarılı", "success");
    })();
  };
  const personelFormKaydet = () => {
    if (programBaslamaCheck() == 1) return;
    const dt = [];
    const MailPersonelId = [];
    atananPersonel.forEach((element) => {
      if (element.mail == 1) MailPersonelId.push(element.k_no);
      dt.push(element.k_no);
    });

    const post = {
      EgitimId: form?.Id,
      PersonelId: dt,
      MailPersonelId: MailPersonelId,
      MailSmsGonder: form?.MailSmsGonder,
    };

    (async () => {
      const rawResponse = await fetch(variables.API_URL + "personeller", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(post),
      });
      personelModalKapat();
      getData();
      notify("tc", "Kayıt işlemi başarılı", "success");
      const content = await rawResponse.json();
    })();
  };
  const formGuncelle = () => {
    if (programBaslamaCheck() == 1) return;
    if (form?.Program == "Seçiniz" || form?.Program == null) {
      alert("Program seçiniz!!!");
      return;
    }
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "egitim", {
        method: "Put",
        headers: {
          Tad: "EgitimeKatilanlar",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(form),
        //body: form
      });
      const content = await rawResponse.json();
      // console.log(content);
      modalKapat();
      getData();
      notify("tc", "Güncelleme işlemi başarılı", "success");
    })();
  };

  const scroller1 = useRef();
  const scroller2 = useRef();

  const keyUpSearch = (e, refn) => {
    let filter = e.target.value.toLowerCase();
    if (refn == "scroller1") {
      let nodes = document.getElementsByClassName("scroller1");
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].innerText.toLowerCase().includes(filter)) {
          nodes[i].style.display = "block";
        } else {
          nodes[i].style.display = "none";
        }
      }
    }
    if (refn == "scroller2") {
      let nodes = document.getElementsByClassName("scroller2");
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].innerText.toLowerCase().includes(filter)) {
          nodes[i].style.display = "block";
        } else {
          nodes[i].style.display = "none";
        }
      }
    }
  };
  const formSil = (id) => {
    if (confirm("Silmek istediğinizden emin misiniz?")) {
      // console.log(id, "id");
      (async () => {
        const rawResponse = await fetch(variables.API_URL + "egitim/" + id, {
          method: "DELETE",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          //headers: { 'Content-Type': 'application/json' },
          //body: JSON.stringify(form)
          //body: form
        });
        modalKapat();
        getData();
        //await rawResponse.status == 200 ? notify("tc", "Silme işlemi başarılı", "success") : notify("tc", await rawResponse.text(), "danger");
        (await rawResponse.ok)
          ? notify("tc", "Silme işlemi başarılı", "success")
          : notify("tc", await rawResponse.text(), "danger");

        // if(await rawResponse.status == 200){
        // 	notify("tc", "Silme işlemi başarılı", "success")
        // }else{
        // 	notify("tc", await rawResponse.text(), "danger")
        // }
      })();
    }
  };
  const PerformansGelisimiOptions = () => {
    var indents = [];
    indents.push(
      <option key={0} value={"Seçiniz"}>
        Seçiniz
      </option>
    );
    indents.push(
      <option key={1} value={"Eğitim"}>
        Eğitim
      </option>
    );
    indents.push(
      <option key={2} value={"Mentörlük"}>
        Mentörlük
      </option>
    );
    indents.push(
      <option key={3} value={"Danışmanlık"}>
        Danışmanlık
      </option>
    );

    return indents;
  };

  function tumu2Click() {
    if (programBaslamaCheck()) return;
    setatananPersonel(personelData);
  }
  function tumunuCikart(dat) {
    // if (programBaslamaCheck()) return;
    if (
      confirm(
        "Eğitime katıldı durumuna değiştirmek istediğinizden emin misiniz?"
      )
    ) {
      setatananPersonel(dat);
    }
  }
  function anketPersonelGoster(elem: never) {
    getAnketData(elem);
    return;
  }
  function anketAmirGoster(elem: never) {
    getAnketDataAmir(elem);
    return;
  }
  function anketDegerGir(elem: never, Secenek1: any): void {
    const dt = structuredClone(anketSorulari);
    dt.forEach((element) => {
      if (element?.Id == elem?.Id) element.KullaniciCevap = Secenek1;
    });
    setanketSorulari(dt);
  }
  React.useEffect(() => {
    getData();
  }, []);
  React.useEffect(() => {
    console.log(anketExcelData);
  }, [anketExcelData]);
  // React.useEffect(() => {
  //     console.log(form)
  // }, [form])
  // React.useEffect(() => {
  // 	console.log(personelData)
  // }, [personelData])
  // React.useEffect(() => {
  // 	console.log(atananPersonel)
  // }, [atananPersonel])
  React.useEffect(() => {
    programBaslamaCheck();
  }, [aktifEgitim]);

  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title as="h4">Performans Oluştur</Card.Title> */}
                <Button variant="secondary" onClick={() => setShowModal(true)}>
                  Program Oluştur
                </Button>
                <span> {data?.length + " adet kayıt bulundu!"}</span>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>##########</th>
                      <th>Program Adı</th>
                      <th>Başlangıç Tarihi</th>
                      <th>Bitiş Tarihi</th>
                      <th>Yer / Kurum</th>
                      <th>Açıklama</th>
                      <th>Personel-Anket</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data != undefined > 0 ? (
                      data.map((elem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {elem.Id}{" "}
                              <span
                                title="Programı Düzenle"
                                className="cursor colorGreen"
                              >
                                <svg
                                  onClick={() => {
                                    duzenle(elem);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                              <span
                                title="Programı Sil??"
                                className="cursor colorRed"
                              >
                                <svg
                                  onClick={() => {
                                    formSil(elem.Id);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </span>
                            </td>
                            <td>
                              <label>{elem.Program}</label> - {elem.EgitimAdi}
                            </td>
                            <td>
                              <label>
                                {elem.BasTarih.replace("T", " ")}
                                {kalanGun(elem.BasTarih) > 0 ? (
                                  <Badge
                                    className="colorWhite"
                                    bg="success"
                                    title={
                                      kalanGun(elem.BasTarih) + " gün kaldı."
                                    }
                                  >
                                    {kalanGun(elem.BasTarih)}
                                  </Badge>
                                ) : null}
                              </label>
                            </td>
                            <td>
                              <label>
                                {elem.BitTarih.replace("T", " ")}
                                {gecenGun(elem.BitTarih) > 0 ? (
                                  <Badge
                                    className="colorWhite"
                                    bg="success"
                                    title={
                                      gecenGun(elem.BitTarih) + " gün geçti."
                                    }
                                  >
                                    {gecenGun(elem.BitTarih)}
                                  </Badge>
                                ) : null}
                              </label>
                            </td>
                            <td>
                              <span title="Yer">{elem.Yer}</span> -{" "}
                              <span title="Kurum">{elem.Kurum}</span>
                            </td>
                            <td>{elem.Aciklama}</td>
                            <td>
                              {elem.KisiSayisi}
                              <span
                                title="Programa katılanlar"
                                onClick={() => personelModalAc(elem)}
                                className="cursor colorBlue"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="currentColor"
                                  className="bi bi-person-add"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                  <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                </svg>
                              </span>
                              <span> </span>
                              <span
                                title="Anket verilerini göster"
                                onClick={() => anketModalAc(elem)}
                                className="cursor colorBlue"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-graph-up-arrow"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                                  />
                                </svg>
                              </span>
                              <span> </span>

                              <span
                                title="Anket sonuçlarını excel olarak indir"
                                onClick={() => anketModalExcelAc(elem)}
                                className="cursor colorBlue"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-file-earmark-excel-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                </svg>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          {/* {"Yükleniyor! "+ data?.length + " adet kayıt bulundu!"} */}
                          {"Yükleniyor! "}
                          <Spinner
                            as="span"
                            animation="border"
                            size="xl"
                            role="status"
                            aria-hidden="true"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {showModal ? (
            <Modal
              size="xl"
              show={true}
              backdrop="static"
              onHide={() => modalKapat()}
            >
              <Modal.Header closeButton>
                <Modal.Title>Program Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form id="formEgitimEkle" className="was-validated">
                  <Form.Group className="mb-3">
                    <Form.Label>Gelişim Programı Yöntemi:</Form.Label>
                    {/* <Form.Control
                                                type="text"
                                                required
                                                value={form?.EgitimAdi}
                                                onChange={(e) => addFormData("EgitimAdi", e.target.value)}
                                            /> */}
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.Program}
                      onChange={(e) => {
                        addFormData("Program", e.target.value);
                      }}
                    >
                      {PerformansGelisimiOptions()}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Program Adı:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.EgitimAdi?.toLocaleUpperCase("tr-TR")}
                      onChange={(e) =>
                        addFormData(
                          "EgitimAdi",
                          e.target.value?.toLocaleUpperCase("tr-TR")
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Başlangıç Tarihi:</Form.Label>
                    <Form.Control
                      type={"datetime-local"}
                      required
                      value={form?.BasTarih}
                      //value={form?.BasTarih?.split('T')[0]}
                      onChange={(e) => addFormData("BasTarih", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Bitiş Tarihi:</Form.Label>
                    <Form.Control
                      type={"datetime-local"}
                      required
                      value={form?.BitTarih}
                      //value={form?.BitTarih?.split('T')[0]}
                      onChange={(e) => addFormData("BitTarih", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Yer:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      maxLength={200}
                      value={form?.Yer}
                      onChange={(e) =>
                        addFormData(
                          "Yer",
                          e.target.value?.toLocaleUpperCase("tr-TR")
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Kurum:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.Kurum}
                      maxLength={200}
                      onChange={(e) =>
                        addFormData(
                          "Kurum",
                          e.target.value?.toLocaleUpperCase("tr-TR")
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Açıklama:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.Aciklama}
                      onChange={(e) =>
                        addFormData(
                          "Aciklama",
                          e.target.value?.toLocaleUpperCase("tr-TR")
                        )
                      }
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Row>
                <Col>
                  {guncelle ? (
                    <Button
                      onClick={formGuncelle}
                      variant="btn btn-primary mw50"
                    >
                      Güncelle
                    </Button>
                  ) : (
                    <Button
                      id="formKaydet"
                      onClick={formKaydet}
                      variant="btn btn-primary mw50"
                    >
                      Kaydet
                    </Button>
                  )}

                  <Button
                    onClick={() => modalKapat()}
                    variant="btn btn-secondary mw50"
                  >
                    Kapat
                  </Button>
                </Col>
              </Row>
            </Modal>
          ) : (
            <></>
          )}
          {showPersonelModal ? (
            <Modal
              size="xl"
              show={true}
              backdrop="static"
              onHide={() => personelModalKapat()}
            >
              <Modal.Header closeButton>
                <Modal.Title>Personel Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form id="formPersonelEkle" className="was-validated">
                  <Row>
                    <Col>
                      <h3 className="text-center">
                        {"(" + atananPersonel?.length + ")"}
                        {form?.EgitimAdi}
                      </h3>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <InputGroup className="mb-3">
                          {/* <input onKeyUp={(e) => keyUpSearch(e, "scroller1")} type="text" className="form-control" placeholder="ARA" /> */}
                          <Form.Control
                            onKeyUp={(e) => keyUpSearch(e, "scroller1")}
                            placeholder="ARA"
                            aria-label="ARA"
                            aria-describedby="basic-addon2"
                            className="h100"
                          />
                          <Button
                            className="colorBlack"
                            onClick={() => {
                              tumunuCikart([]);
                            }}
                            variant="outline-secondary"
                            id="button-addon2"
                          >
                            <b>Tümü</b>
                            <span
                              title="Tümünü çıkart"
                              className="cursor  opacity05"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="bi bi-bookmark-check-fill bookmark-check-fill float-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
                                />
                              </svg>
                            </span>
                          </Button>
                        </InputGroup>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <InputGroup className="mb-3">
                          <Form.Control
                            onKeyUp={(e) => keyUpSearch(e, "scroller2")}
                            placeholder="ARA"
                            aria-label="ARA"
                            aria-describedby="basic-addon2"
                            className="h100"
                          />
                          <Button
                            className="colorBlack tumu2"
                            onClick={() => {
                              tumu2Click();
                            }}
                            variant="outline-secondary"
                            id="button-addon2"
                          >
                            <b>Tümü</b>
                            <span title="Tümünü Ekle" className="cursor ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="bi bi-bookmark-check-fill bookmark-check-fill float-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
                                />
                              </svg>
                            </span>
                          </Button>
                        </InputGroup>
                      </Row>

                      {/* <input onKeyUp={(e) => keyUpSearch(e, "scroller2")} type="text" className="form-control" placeholder="ARA" /> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup ref={scroller1} className="scroller">
                        {/* <ListGroup.Item className=" scroller1" variant="light" >
                                                        <b>Ad Soyad</b>
                                                        <span onClick={() => { setatananPersonel([]) }} title="Tümünü çıkart" className="cursor opacity05" >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-bookmark-check-fill bookmark-check-fill float-right" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                                            </svg>
                                                        </span>

                                                    </ListGroup.Item> */}
                        {atananPersonel.map((elem, index) => {
                          return (
                            <ListGroup.Item
                              data-key={elem.k_no}
                              key={elem.k_no}
                              className=" scroller1"
                              variant="light"
                            >
                              <span>{elem.k_unvan}</span>
                              {elem?.katildi == false ? (
                                <span
                                  onClick={() => {
                                    egitimePersonelKatildi(elem);
                                  }}
                                  title="Personel Eğitime KATILMADI!"
                                  className="cursor"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bookmark-uncheck-fill bi-emoji-frown-fill float-right"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                  </svg>
                                </span>
                              ) : (
                                <span
                                  onClick={() => {
                                    egitimePersonelKatilmadi(elem);
                                  }}
                                  title="Personel Eğitime Katıldı"
                                  className="cursor"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bookmark-check-fill bi-emoji-smile-fill float-right"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                  </svg>
                                </span>
                              )}
                              {elem?.tamaminaKatildi == false ? (
                                <span
                                  onClick={() => {
                                    egitimePersonelKatildiTamamina(elem);
                                  }}
                                  title="Personel Eğitimin Tamamına KATILMADI!"
                                  className="cursor"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-battery-half bookmark-uncheck-fill float-right"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 6h5v4H2z" />
                                    <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8" />
                                  </svg>
                                </span>
                              ) : (
                                <span
                                  onClick={() => {
                                    egitimePersonelKatilmadiTamamina(elem);
                                  }}
                                  title="Personel Eğitimin Tamamına Katıldı"
                                  className="cursor"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-battery-full bookmark-check-fill float-right"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 6h10v4H2z" />
                                    <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8" />
                                  </svg>
                                </span>
                              )}

                              <span
                                onClick={() => {
                                  egitimePersonelKatimadi(elem);
                                }}
                                title="Personel Eğitime Katıldı"
                                className="cursor"
                              ></span>

                              <span
                                onClick={() => {
                                  egitimePersonelCikart(elem);
                                }}
                                title="Listeye Ekle/Çıkart"
                                className="cursor"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  className="bi bi-bookmark-check-fill bookmark-check-fill float-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
                                  />
                                </svg>
                              </span>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Col>
                    <Col>
                      <ListGroup ref={scroller2} className="scroller">
                        {/* <ListGroup.Item className=" scroller2" variant="light" >
                                                        <b>Ad Soyad2</b>
                                                        <span onClick={() => { setatananPersonel(personelData) }} title="Tümünü ekle" className="cursor " >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-bookmark-check-fill bookmark-check-fill float-right" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                                            </svg>
                                                        </span>

                                                    </ListGroup.Item> */}
                        {personelData.map((elem, index) => {
                          return (
                            <ListGroup.Item
                              data-key={elem.k_no}
                              key={elem.k_no}
                              className="cursor scroller2"
                              variant="light"
                              onClick={() => {
                                egitimePersonelEkle(elem);
                              }}
                            >
                              {elem.k_unvan}
                              {elem.egitimId > 0 ? <BookmarkCheckFill /> : null}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Row>
                <Col>
                  <Form.Check // prettier-ignore
                    type="checkbox"
                    id="chkMailSmsGonder"
                    checked={form?.MailSmsGonder}
                    onChange={(e) =>
                      addFormData("MailSmsGonder", e.target.checked)
                    }
                    label="Mail & SMS Gönder"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {guncelle ? (
                    <Button
                      onClick={formGuncelle}
                      variant="btn btn-primary mw50"
                    >
                      Güncelle
                    </Button>
                  ) : (
                    <Button
                      title="Kaydet!"
                      id="personelFormKaydet"
                      onClick={personelFormKaydet}
                      variant="btn btn-primary mw50"
                    >
                      Kaydet
                    </Button>
                  )}

                  <Button
                    title="Yaptığınız işlemleri kaydetmeden çıkmaya yarar!"
                    onClick={() => personelModalKapat()}
                    variant="btn btn-secondary mw50"
                  >
                    Kapat & Çık
                  </Button>
                </Col>
              </Row>
            </Modal>
          ) : (
            <></>
          )}
          {showAnketModal ? (
            <Modal
              size="xl"
              show={true}
              backdrop="static"
              onHide={() => anketModalKapat()}
            >
              <Modal.Header closeButton>
                <Modal.Title>Anket Sonuçları</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form id="formPersonelEkle" className="was-validated">
                  <Row>
                    <Col>
                      <h3 className="text-center">
                        {form?.EgitimAdi}
                        {"(" + atananPersonel?.length + " sonuç bulundu)"}
                      </h3>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <InputGroup className="mb-3">
                          {/* <input onKeyUp={(e) => keyUpSearch(e, "scroller1")} type="text" className="form-control" placeholder="ARA" /> */}
                          <Form.Control
                            onKeyUp={(e) => keyUpSearch(e, "scroller1")}
                            placeholder="ARA"
                            aria-label="ARA"
                            aria-describedby="basic-addon2"
                            className="h100"
                          />
                        </InputGroup>
                      </Row>
                    </Col>
                    {/* <Col>
                                                <Row>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            onKeyUp={(e) => keyUpSearch(e, "scroller2")}
                                                            placeholder="ARA"
                                                            aria-label="ARA"
                                                            aria-describedby="basic-addon2"
                                                            className="h100"
                                                        />
                                                    </InputGroup>
                                                </Row>
                                            </Col> */}
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup ref={scroller1} className="scroller">
                        {atananPersonel.map((elem, index) => {
                          return (
                            <ListGroup.Item
                              data-key={elem.k_no}
                              key={elem.k_no}
                              className=" scroller1"
                              variant="light"
                            >
                              <span>{elem.k_unvan}</span>
                              <span
                                onClick={() => {
                                  anketAmirGoster(elem);
                                }}
                                title="Amir Anketi Göster"
                                className="cursor"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-person-badge-fill bookmark-check-fill float-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                                </svg>
                              </span>
                              <span className="float-right">   </span>
                              <span
                                onClick={() => {
                                  anketPersonelGoster(elem);
                                }}
                                title="Personel Anketi Göster"
                                className="cursor"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-graph-up-arrow bookmark-check-fill float-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                                  />
                                </svg>
                              </span>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Col>
                    <Col>
                      <ListGroup ref={scroller2} className="">
                        {/* {
                                                        personelData.map((elem, index) => {
                                                            return (
                                                                <ListGroup.Item data-key={elem.k_no} key={elem.k_no} className="cursor scroller2" variant="light" onClick={() => { egitimePersonelEkle(elem) }}>
                                                                    {elem.k_unvan}
                                                                    {
                                                                        elem.egitimId > 0 ? <BookmarkCheckFill /> : null
                                                                    }
                                                                </ListGroup.Item>
                                                            )
                                                        })
                                                    } */}
                        {anketSorulari.map((elem, index) => {
                          return (
                            <div key={index}>
                              <h3>
                                {index + 1}.{elem.Soru}
                              </h3>
                              <div>
                                {/* <span>Cevap: {JSON.stringify(elem)}</span> */}
                                {elem.Tip == "YAZILI" ? (
                                  <textarea
                                    onChange={(e) =>
                                      anketDegerGir(elem, e.target.value)
                                    }
                                    className="form-control"
                                    value={elem?.KullaniciCevap ?? ""}
                                  ></textarea>
                                ) : (
                                  <div>
                                    <ButtonToolbar>
                                      <ButtonGroup bssize="large">
                                        <Button
                                          className={
                                            elem.KullaniciCevap == elem.Secenek1
                                              ? " active "
                                              : ""
                                          }
                                          onClick={() =>
                                            anketDegerGir(elem, elem.Secenek1)
                                          }
                                        >
                                          {elem.Secenek1}
                                        </Button>
                                        <Button
                                          className={
                                            elem.KullaniciCevap == elem.Secenek2
                                              ? " active "
                                              : ""
                                          }
                                          onClick={() =>
                                            anketDegerGir(elem, elem.Secenek2)
                                          }
                                        >
                                          {elem.Secenek2}
                                        </Button>
                                        <Button
                                          className={
                                            elem.KullaniciCevap == elem.Secenek3
                                              ? " active "
                                              : ""
                                          }
                                          onClick={() =>
                                            anketDegerGir(elem, elem.Secenek3)
                                          }
                                        >
                                          {elem.Secenek3}
                                        </Button>
                                        <Button
                                          className={
                                            elem.KullaniciCevap == elem.Secenek4
                                              ? " active "
                                              : ""
                                          }
                                          onClick={() =>
                                            anketDegerGir(elem, elem.Secenek4)
                                          }
                                        >
                                          {elem.Secenek4}
                                        </Button>
                                        <Button
                                          className={
                                            elem.KullaniciCevap == elem.Secenek5
                                              ? " active "
                                              : ""
                                          }
                                          onClick={() =>
                                            anketDegerGir(elem, elem.Secenek5)
                                          }
                                        >
                                          {elem.Secenek5}
                                        </Button>
                                      </ButtonGroup>
                                    </ButtonToolbar>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </ListGroup>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Row>
                <Col>
                  {/* {
                                            guncelle ?
                                                <Button onClick={formGuncelle} variant="btn btn-primary mw50" >
                                                    Güncelle
                                                </Button>
                                                :
                                                <Button title="Seçilen kişilere mail ve sms gönderir!" id="personelFormKaydet" onClick={()=>console.log()} variant="btn btn-primary mw50" >
                                                    Kaydet 
                                                </Button>
                                        } */}

                  <Button
                    title="Yaptığınız işlemleri kaydetmeden çıkmaya yarar!"
                    onClick={() => anketModalKapat()}
                    variant="btn btn-secondary mw100"
                  >
                    Kapat & Çık
                  </Button>
                </Col>
              </Row>
            </Modal>
          ) : (
            <></>
          )}
          {showAnketModalExcel ? (
            <Modal
              size="xl"
              show={true}
              backdrop="static"
              onHide={() => setshowAnketModalExcel(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Anket Sonuçları Excel Download
                  <DownloadTableExcel
                    filename="AnketExcelFull"
                    sheet="AnketExcelFull"
                    currentTableRef={tableRef.current}
                  >
                    <Button variant="secondary"> Excel'e aktar </Button>
                  </DownloadTableExcel>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Col>
                <div className="scroller">
                <Table ref={tableRef}>
                    <thead>
                      <tr>
                        <th>AdSoyad</th>
                        <th>EğitimAdı</th>
                        <th>GorusVeOneri</th>
                        <th>Memnunum</th>
                        <th>GelisimimeKatkiSagladi</th>
                        <th>EgiticiTecrubesiYeterliydi</th>
                        <th>ProgramAcikVeAnlasilir</th>
                        <th>BasTarih</th>
                        <th>BitTarih</th>
                      </tr>
                    </thead>
                    <tbody>
                      {anketExcelData != undefined > 0 ? (
                        anketExcelData.map((elem, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {elem.AdSoyad}{" "}
                                 
                              </td>
                              <td>
                                {elem.EgitimId}-
                                {elem.EgitimAdi}
                              </td>
                              
                              <td>
                                <span title="GorusVeOneri">{elem?.GorusVeOneri}</span>  
                              </td>
                              <td>{elem.Memnunum}</td>
                              <td>
                                {elem.GelisimimeKatkiSagladi}
                              </td>
							                <td>
                                {elem.EgiticiTecrubesiYeterliydi}
                              </td>
							                <td>
                                {elem.ProgramAcikVeAnlasilir}
                              </td>
							                <td>
                                <label>
                                  {elem.BasTarih.replace("T", " ")}
                                </label>
                              </td>
                              <td>
                                <label>
                                  {elem.BitTarih.replace("T", " ")}
                                </label>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>
                            {/* {"Yükleniyor! "+ data?.length + " adet kayıt bulundu!"} */}
                            {"Yükleniyor! "}
                            <Spinner
                              as="span"
                              animation="border"
                              size="xl"
                              role="status"
                              aria-hidden="true"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                  
                </Col>
              </Modal.Body>
              <Row>
                <Col>
                  <Button
                    title="Yaptığınız işlemleri kaydetmeden çıkmaya yarar!"
                    onClick={() => setshowAnketModalExcel(false)}
                    variant="btn btn-secondary mw100"
                  >
                    Kapat & Çık
                  </Button>
                </Col>
              </Row>
            </Modal>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Egitim;
