import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Tooltip,
  ListGroup,
  Spinner,
  OverlayTrigger,
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import InputMask from "react-input-mask";

function UyeTaleplerim() {
  const history = useHistory();

  const [guncelle, setGuncelle] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [donusTipleri, setDonusTipleri] = React.useState([
    { key: 1, val: "Memnuniyet" },
    { key: 2, val: "Talep" },
    { key: 3, val: "Şikayet(düşük)" },
    { key: 4, val: "Şikayet(normal)" },
    { key: 5, val: "Şikayet(yüksek)" },
    { key: 6, val: "Öneri" },
    { key: 7, val: "Bilgi" },
  ]);
  const geriBildirimKanali = [
    { key: 1, val: "ÜYE ZİYARETİ" },
    { key: 2, val: "HİZMET ALIMI" },
    { key: 3, val: "WEB SAYFASI" },
    { key: 4, val: "E-MAİL" },
    { key: 5, val: "TELEFON" },
    { key: 6, val: "SOSYAL MEDYA" },
    { key: 7, val: "DİĞER" },
  ];
  const geriBildirimKaynagi = [
    { key: 1, val: "YÖNETİMDEN KAYNAKLI" },
    { key: 2, val: "PERSONELDEN KAYNAKLI" },
    { key: 3, val: "ÇALIŞMA ORTAMI VE ALT YAPI KAYNAKLI" },
    { key: 4, val: "HİZMET KALİTESİ KAYNAKLI" },
    { key: 5, val: "FİNANSAL DURUM KAYNAKLI" },
    { key: 6, val: "ETKİNLİK(EĞİTİM, FUAR VB.) KAYNAKLI" },
    { key: 7, val: "LOBİ FAALİYETİ KAYNAKLI" },
  ];
  const geriBildirimTuru = [
    { key: 1, val: "ŞİKAYET" },
    { key: 2, val: "TALEP" },
    { key: 3, val: "ÖNERİ" },
  ];
  const geriBildirimOnceligi = [
    { key: 1, val: "ACİL/YÜKSEK" },
    { key: 2, val: "NORMAL" },
    { key: 3, val: "DÜŞÜK" },
  ];
  const geriBildirimArastirilmasiveCozumunSaglanmasi = [
    { key: 1, val: "GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜLMESİ" },
    { key: 2, val: "DÜZELTME FAALİYETİ" },
  ];
  const cozumunGeriBildirimSahibineBildirilmesi = [
    { key: 1, val: "ÇÖZÜMÜN BİLDİRİLMESİ" },
    { key: 2, val: "DÜZELTME FAALİYETİNİN BİLDİRİLMESİ" },
  ];
  const geriBildirimSahibininCozumSurecindenMemnuniyeti = [
    { key: 1, val: "İYİ" },
    { key: 2, val: "NORMAL" },
    { key: 3, val: "KÖTÜ" },
  ];
  const geriBildirimDurumu = [
    { key: 1, val: "SONUÇLANDIRILDI" },
    { key: 2, val: "KAPATILDI" },
  ];
  const initialForm = {
    DonusTipi: "0",
    Kisi: "",
    Mail: "",
    Personel: 0,
    OdaSicil: "",
    Tarih: new Date().toJSON(),
    GeriBildirimKanali: "geriBildirimKanali",
    GeriBildirimKaynagi: "geriBildirimKaynagi",
    GeriBildirimTuru: "geriBildirimTuru",
    GeriBildirimOnceligi: "geriBildirimOnceligi",
    GeriBildirimAlindiTarih: null,
    Tel: "",
  };
  const [form, setForm] = React.useState(initialForm);
  const [showModal, setShowModal] = React.useState(false);
  const tableRef = useRef(null);
  const [detay, setDetay] = React.useState([]);
  const [detayRow, setDetayRow] = React.useState({
    XKey: "GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜMÜNÜN SAĞLANMASI",
    XValue: "-",
  });
  const [d2, setd2] = React.useState([]);
  const [d3, setd3] = React.useState([]);
  const [d4, setd4] = React.useState([]);
  const [bildirimDurumlari, setbildirimDurumlari] = React.useState([
    { key: 1, val: "Geri Bildirim Alındı" },
    { key: 2, val: "İşlem Devam Ediyor" },
    { key: 3, val: "İşlem Beklemede" },
    { key: 4, val: "Yöneticide" },
    { key: 5, val: "DF Açıldı" },
    { key: 6, val: "Mutabakat Sağlanamadı" },
    { key: 7, val: "Dış Kaynaklara Yönlendirildi" },
    { key: 8, val: "Kapatıldı" },
    { key: 9, val: "Sonuçlandırıldı" },
  ]);

  const token = secureLocalStorage.getItem("token");
  const notificationAlertRef = React.useRef(null);

  const fillBildirimDurumuSelect = () => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < bildirimDurumlari.length; i++) {
      const element = bildirimDurumlari[i];
      indents.push(
        <option key={element.key} value={element.key}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const notify = (place, textt, typee) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{textt}</div>
        </div>
      ),
      type: typee ?? type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getData = async () => {
    const istek = await fetch(variables.API_URL + "page/GetUyeTaleplerim", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        Tad: "UyeTaleplerim",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (istek.ok) {
      setData(await istek?.json());
    } else {
      let txt = await istek.text();
      alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      history.push("/admin/giris");
    }
  };
  const detaySil = (id) => {
    if (confirm("Silmek istediğinizden emin misiniz?")) {
      (async () => {
        const rawResponse = await fetch(variables.API_URL + "Page/", {
          method: "DELETE",
          headers: {
            Accept: "application/json, text/plain, */*",
            Tad: "UyeTalepDetay",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ Id: id, tad: "UyeTalepDetay" }),
          //body: form
        });
        //const content = await rawResponse.json();
        //modalKapat();
        //getData();
        talepDetayGetir(form?.Id);
        notify("tc", "Silme işlemi başarılı", "success");
      })();
    }
  };
  const addFormData = (key, value) => {
    const ff = structuredClone(form);
    ff[key] = value;
    setForm(ff);
  };
  const duzenle = (elem) => {
    talepDetayGetir(elem.Id);
    setGuncelle(true);
    setShowModal(true);
    setForm(elem);
  };
  const formKaydet = () => {
    const ff = structuredClone(form);
    ff.tad = "InsertUyeTalep";
    //console.log(ff);return;
    (async () => {
      try {
        const req = await fetch(variables.API_URL + "UyeTalep/", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            Tad: "InsertUyeTalep",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(ff),
        });
        const res = req.json();
        req.ok
          ? notify("tc", "Kayıt işlemi başarılı", "success")
          : notify(
              "tc",
              "Bir hata oluşmuş gibi gözüküyor. Lütfen sistem yetkilisi ile iletişime geçin.",
              "danger"
            );
      } catch (error) {
        console.log(error);
        alert("İşlem sırasında hata! Yetkiniz olmayabilir.");
        console.log(2);
      } finally {
        modalKapat();
        getData();
      }
    })();
  };
  const formGuncelle = () => {
    const ff = structuredClone(form);
    ff.tad = "UyeTalep";
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "page/", {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: "UyeTalep",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(ff),
        //body: form
      });
      const content = await rawResponse.json();
      modalKapat();
      getData();
      notify("tc", "Güncelleme işlemi başarılı", "success");
    })();
  };
  const formSil = (id) => {
    if (confirm("Silmek istediğinizden emin misiniz?")) {
      // console.log(id, "id");
      const ff = structuredClone(form);
      ff.tad = "UyeTalep";
      ff.Id = id;
      (async () => {
        const rawResponse = await fetch(variables.API_URL + "Page/", {
          method: "DELETE",
          headers: {
            Accept: "application/json, text/plain, */*",
            Tad: "UyeTalep",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          //headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(ff),
          //body: form
        });
        //const content = await rawResponse.json();
        modalKapat();
        getData();
        notify("tc", "Silme işlemi başarılı", "success");
      })();
    }
  };
  const fillSelect = (arr) => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      indents.push(
        <option key={element.key} value={element.val}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const fillSelectv2 = (arr) => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      indents.push(
        <option key={element.key} value={element.key}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const FormBosalt = () => {
    //console.log(112)
    setForm(initialForm);
  };
  const modalKapat = () => {
    setShowModal(false);
    setGuncelle(false);
    //FormBosalt();
    setDetay([]);
  };
  const satirEkle = (arr) => {
    const dt = structuredClone(arr);
    dt.UyeTalepId = form.Id;
    dt.tad = "PostUyeTalepDetay";
    //console.log(dt);return;
    (async () => {
      const istek = await fetch(variables.API_URL + "Page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: dt.tad,
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dt),
      });
      if (await istek.ok) {
        //console.log("talepDetayGetir");
        talepDetayGetir(form.Id);
      } else {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      }
    })();
  };
  const talepDetayGetir = (talepId) => {
    //return;
    (async () => {
      const istek = await fetch(variables.API_URL + "Page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: "GetUyeTalepDetay",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ uyetalepid: talepId, tad: "GetUyeTalepDetay" }),
      });
      if (await istek.ok) {
        //console.log(await istek?.json());
        setDetay(await istek?.json());
      } else {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      }
    })();
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title as="h4">Üye Bildirimlerim</Card.Title> */}
                <Button variant="secondary" onClick={() => setShowModal(true)}>
                  Üye Bildirim Ekle
                </Button>
                <DownloadTableExcel
                  filename="ÜyeTaleplerimTablo"
                  sheet="ÜyeTaleplerim"
                  currentTableRef={tableRef.current}
                >
                  <Button variant="secondary"> Excel'e aktar </Button>
                </DownloadTableExcel>
                <span> {data?.length + " adet kayıt bulundu!"}</span>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table ref={tableRef} className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Aksiyon</th>
                      <th>Sicil</th>
                      <th>İlgiliKişi</th>
                      <th>Açıklama</th>
                      <th>Tel</th>
                      <th>Mail</th>
                      <th>Tarih</th>
                      <th>Dönüş Tipi</th>
                      <th>Personel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data != undefined ? (
                      data.map((elem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {elem.Id}{" "}
                              <span className="cursor colorGreen">
                                <svg
                                  onClick={() => {
                                    duzenle(elem);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                              <span className="cursor colorRed">
                                <svg
                                  onClick={() => {
                                    formSil(elem.Id);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </span>
                            </td>
                            <td>
                              {elem.AlinanAksiyon != ""
                                ? elem.AlinanAksiyon
                                : "-"}
                            </td>
                            <td>{elem.OdaSicil}</td>
                            <td>{elem.Kisi}</td>
                            <td>{elem.Aciklama}</td>
                            <td>{elem.Tel}</td>
                            <td>{elem.Mail}</td>
                            <td>{elem.Tarih?.split("T")[0]}</td>
                            <td>
                              {donusTipleri.find((C) => C.key == elem.DonusTipi)
                                ?.val ?? "Bulunamadı_" + elem.DonusTipi}
                            </td>
                            <td>{elem.Personel}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          {data?.length + " adet kayıt bulundu!"}...
                          <Spinner
                            as="span"
                            animation="border"
                            size="xl"
                            role="status"
                            aria-hidden="true"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {showModal ? (
            <Modal
              size="xl"
              show={true}
              backdrop="static"
              onHide={() => modalKapat()}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Geri Bildirim Ekle
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Formu Boşalt</Tooltip>
                    }
                  >
                    <svg
                      onClick={(e) => modalKapat()}
                      title="Formu Boşalt"
                      width="22"
                      height="22"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                  </OverlayTrigger>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form as={Row} id="formUyeTalepEkle" className="was-validated">
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Tarihi:</Form.Label>
                    <Form.Control
                      type={"date"}
                      required
                      value={form?.Tarih?.split("T")[0]}
                      onChange={(e) => addFormData("Tarih", e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Oda Sicil:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.OdaSicil}
                      onChange={(e) => addFormData("OdaSicil", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>İletişim Ad Soyad:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.Kisi}
                      onChange={(e) => addFormData("Kisi", e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Telefon:</Form.Label>
                    <InputMask
                      value={form?.Tel}
                      onChange={(e) => addFormData("Tel", e.target.value)}
                      required
                      className={`form-control ${
                        form?.Tel?.length > 8 && !isNaN(+form?.Tel)
                          ? " bordervalid "
                          : " borderinvalid "
                      }`}
                      mask="\5999999999"
                      maskChar="_"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Mail:</Form.Label>
                    <Form.Control
                      type="mail"
                      required
                      value={form?.Mail}
                      onChange={(e) => addFormData("Mail", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Kanalı:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      // value={form?.DonusTipi}
                      value={form?.GeriBildirimKanali}
                      onChange={(e) => {
                        addFormData("GeriBildirimKanali", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimKanali)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Kaynağı:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.GeriBildirimKaynagi}
                      onChange={(e) => {
                        addFormData("GeriBildirimKaynagi", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimKaynagi)}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Türü:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.GeriBildirimTuru}
                      onChange={(e) => {
                        addFormData("GeriBildirimTuru", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimTuru)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Önceliği:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.GeriBildirimOnceligi}
                      onChange={(e) => {
                        addFormData("GeriBildirimOnceligi", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimOnceligi)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Geri Bildirim Metni:</Form.Label>
                    <textarea
                      className="form-control"
                      required
                      value={form?.Aciklama}
                      onChange={(e) => addFormData("Aciklama", e.target.value)}
                    ></textarea>
                  </Form.Group>
                  {guncelle ? (
                    <Container>
                      <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                          <Form.Label>
                            Geri Bildirimin İşleme Alındığının Bildirilme Zamanı:
                          </Form.Label>
                          <Form.Control
                            type={"datetime-local"}
                            required
                            value={form?.GeriBildirimAlindiTarih}
                            onChange={(e) =>
                              addFormData(
                                "GeriBildirimAlindiTarih",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                <strong>GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜMÜNÜN SAĞLANMASI</strong>
                              </Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={detayRow?.XValue}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...detayRow,
                                          XValue: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(
                                        geriBildirimArastirilmasiveCozumunSaglanmasi
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={detayRow?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...detayRow,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      placeholder="Alınan Aksiyon"
                                      required
                                      value={detayRow?.d1AlinanAksiyon}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...detayRow,
                                          AlinanAksiyon: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={(e) => satirEkle(detayRow)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +EKLE
                                  </Button>
                                </Col>
                              </Row>
                              {detay.length > -1 ? (
                                detay?.map((elem, index) => {
                                  //console.log(elem);
                                  return elem.XKey=="GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜMÜNÜN SAĞLANMASI"?(
                                      <Container>
                                        <Row
                                          key={index}
                                          className="border-top border-dark mt-3 pt-2"
                                        >
                                          <Col><small>{elem.XValue}</small></Col>
                                          <Col><small>{elem.Tarih?.split("T")[0]}</small></Col>
                                          <Col><small>{elem.AlinanAksiyon}</small></Col>
                                          <Col>
                                            <Button
                                              onClick={(e) => detaySil(elem.Id)}
                                              className="btn btn-danger mw100"
                                              variant="danger"
                                              size="sm"
                                            >
                                              sil
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Container>
                                    
                                  ):<></>
                                  
                                })
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      {/* 
                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>ÇÖZÜMÜN GERİ BİLDİRİM SAHİBİNE BİLDİRİLMESİ</Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={d2?.BildirimDurumu}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d2,
                                          BildirimDurumu: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(cozumunGeriBildirimSahibineBildirilmesi)}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={d2?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d2,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      placeholder="Alınan Aksiyon"
                                      required
                                      value={d2?.AlinanAksiyon}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d2,
                                          AlinanAksiyon: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={e=>satirEkle(d2)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +EKLE
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>GERİ BİLDİRİM SAHİBİNİN ÇÖZÜM SÜRECİNDEN MEMNUNİYETİ</Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={d3?.BildirimDurumu}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d3,
                                          BildirimDurumu: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(geriBildirimSahibininCozumSurecindenMemnuniyeti)}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={d3?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d3,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col></Col>
                                <Col>
                                  <Button
                                    onClick={e=>satirEkle(d3)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +EKLE
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>GERİ BİLDİRİM DURUMU </Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={d4?.BildirimDurumu}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d4,
                                          BildirimDurumu: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(geriBildirimDurumu)}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={d4?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow({
                                          ...d4,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col></Col>
                                <Col>
                                  <Button
                                    onClick={e=>satirEkle(d4)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +EKLE
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                     */}
                    </Container>
                  ) : (
                    <></>
                  )}
                  
                </Form>
              </Modal.Body>
              <Row>
                <Col>
                  {guncelle ? (
                    <Button
                      onClick={formGuncelle}
                      variant="btn btn-primary mw50"
                    >
                      Güncelle
                    </Button>
                  ) : (
                    <Button
                      id="formKaydet"
                      onClick={formKaydet}
                      variant="btn btn-primary mw50"
                    >
                      Kaydet
                    </Button>
                  )}
                  <Button
                    onClick={() => modalKapat()}
                    variant="btn btn-secondary mw50"
                  >
                    Kapat
                  </Button>
                </Col>
              </Row>
            </Modal>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </>
  );
}

export default UyeTaleplerim;
