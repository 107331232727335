/*!


*/
import React, { Component } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";

import routes from "routes.js";

function Header() {
    const location = useLocation();
    const history = useHistory();
    const mobileSidebarToggle = (e) => {
        e.preventDefault();
        document.documentElement.classList.toggle("nav-open");
        var node = document.createElement("div");
        node.id = "bodyClick";
        node.onclick = function () {
            this.parentElement.removeChild(this);
            document.documentElement.classList.toggle("nav-open");
        };
        document.body.appendChild(node);
    };

    const getBrandText = () => {
        for (let i = 0; i < routes.length; i++) {
            // if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
            //     return routes[i].name;
            // }
            if (location.pathname == (routes[i].layout + routes[i].path)) {
                return routes[i].name;
            }
        }
        return "X";
    };
    const kadi = secureLocalStorage.getItem('kadi');
    
   
    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
                    <Button
                        variant="dark"
                        className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
                        onClick={mobileSidebarToggle}
                    >
                        <i className="fas fa-ellipsis-v"></i>
                    </Button>
                    <Navbar.Brand
                        href="#home"
                        onClick={(e) => e.preventDefault()}
                        className="mr-2"
                    >
                        {getBrandText()}
                    </Navbar.Brand>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav mr-auto" navbar>
                        {/*<Nav.Item>
                            <Nav.Link
                                data-toggle="dropdown"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                className="m-0"
                            >
                                <i className="nc-icon nc-palette"></i>
                                <span className="d-lg-none ml-1">Dashboard</span>
                            </Nav.Link>
                        </Nav.Item>
                         <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle
                                as={Nav.Link}
                                data-toggle="dropdown"
                                id="dropdown-67443507"
                                variant="default"
                                className="m-0"
                            >
                                <i className="nc-icon nc-planet"></i>
                                <span className="notification">5</span>
                                <span className="d-lg-none ml-1">Notification</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Notification 1
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Notification 2
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Notification 3
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Notification 4
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Another notification
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Nav.Item>
                            <Nav.Link
                                className="m-0"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="nc-icon nc-zoom-split"></i>
                                <span className="d-lg-block"> Search</span>
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                    <Nav className="ml-auto" navbar>
                        {/* <Nav.Item>
                            <Nav.Link
                                className="m-0"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                            >
                                <span className="no-icon">Account</span>
                            </Nav.Link>
                        </Nav.Item>
                        
                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle
                                aria-expanded={false}
                                aria-haspopup={true}
                                as={Nav.Link}
                                data-toggle="dropdown"
                                id="navbarDropdownMenuLink"
                                variant="default"
                                className="m-0"
                            >
                                <span className="no-icon">Dropdown</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Action
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Another action
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Something
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Something else here
                                </Dropdown.Item>
                                <div className="divider"></div>
                                <Dropdown.Item
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Separated link
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        <Nav.Item>
                            {/* <Nav.Link
                                className="m-0"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                            >
                                <span className="no-icon">Log out</span>
                            </Nav.Link> */}
                            <Nav.Link
                                //to={"http://localhost:3000/admin/giris"}
                                className="nav-link m-0 p-1"
                                onClick={() => history.push("/admin/giris")}
                            >
                                {/* <i className={el.icon}>> </i> */}
                                <p>{kadi}</p>
                                <p>Çıkış <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sign-no-right-turn-fill" viewBox="0 0 16 16">
                                    <path d="M14 13.292A8 8 0 0 0 2.707 2l4.097 4.098C7.025 6.034 7.259 6 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658a.265.265 0 0 1-.026.02L14 13.291Zm-.708.708A8 8 0 0 1 2 2.707l3.885 3.884A2.495 2.495 0 0 0 5 8.5V11h1V8.5c0-.489.234-.923.596-1.197l6.696 6.696Z" />
                                    <path d="M7.707 7 9 8.293V7H7.707Z" />
                                </svg></p>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
