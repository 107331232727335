import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import {
    Button,
    Card,
    Modal, Form,
    Table,
    Container,
    Row,
    Col, Dropdown, OverlayTrigger,
    ListGroup, Spinner, FormControl
} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export let options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',// as const,
        },
        title: {
            display: true,
            text: 'BİRİM SEÇİN!',
        },
    },
};
export let data = {
    labels: [2023, 2022, 2021],
    datasets: [
        {
            label: 'TARİH SEÇİN!',
            data: [10, 20, 30],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        // {
        //     label: 'Dataset 2',
        //     data: [50,40],
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // },
    ],
};
const IstatistikRaporFilterRow = (props) => {
    return (
        <Row>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label><b>Birim:</b></Form.Label>
                    <Form.Select className="form-control" aria-label="select"
                        value={props.form?.Birimler}
                        onChange={(e) => { props.addFormData("Birimler", e.target.value); }}>
                        {props.birimler()}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label><b>Yıl Başlangıç:</b></Form.Label>
                    <Form.Select className="form-control" aria-label="select"
                        value={props.form?.YilBas}
                        onChange={(e) => { props.addFormData("YilBas", e.target.value); }}>
                        {props.buyil}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label><b>Yıl Bitiş:</b></Form.Label>
                    <Form.Select className="form-control" aria-label="select"
                        value={props.form?.YilBit}
                        onChange={(e) => { props.addFormData("YilBit", e.target.value); }}>
                        {props.buyil}
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
    )
}

function IstatistikRapor() {
    // const initialForm = { "Birimler": 0, "YilBas": (new Date().getFullYear()), "YilBit": (new Date().getFullYear()) };
    const initialForm = { "Birimler": 0, "Kategoriler": 0, "AltKategoriler": 0, "YilBas": (new Date().getFullYear()), "YilBit": (new Date().getFullYear()) };
    const [showModal, setShowModal] = React.useState(false);
    const [form, setForm] = React.useState(initialForm);
    const [tablo, setTablo] = React.useState([]);
    const initChartData = { "data": data, "options": options }
    const [chartDatas, setChartDatas] = React.useState(initChartData);
    const [birimler, setBirimler] = React.useState([]);
    const [kategoriler, setKategoriler] = React.useState([]);
    const [altKategoriler, setAltKategoriler] = React.useState([]);
    const token = secureLocalStorage.getItem('token');
    const notificationAlertRef = React.useRef(null);
    const tableRef = useRef(null);

    const notify = (place, textt, typee) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {textt}
                    </div>
                </div>
            ),
            type: typee ?? type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    function modalKapat() {
        setShowModal(false);
        //setForm(initialForm);
    }
    const getYears = () => {
        var indents = [];
        for (var i = (new Date().getFullYear()); i > 2008; i--) {
            indents.push(<option key={i} value={i}>{i}</option>);
        }
        return indents;
    }
    const fillBirimlerSelect = () => {
        var indents = [];
        indents.push(<option key={0} value={0}>TÜMÜ</option>);
        for (let i = 0; i < birimler.length; i++) {
            const element = birimler[i];
            indents.push(<option key={element.BirimId} value={element.BirimId}>{element.Birimler}</option>);
        }
        return indents;
    }
    const fillKategorilerSelect = () => {
        var indents = [];
        indents.push(<option key={0} value={0}>TÜMÜ</option>);
        for (let i = 0; i < kategoriler.length; i++) {
            const element = kategoriler[i];
            indents.push(<option key={element.KategoriId} value={element.KategoriId}>{element.KategoriAd}</option>);
        }
        return indents;
    }
    const fillAltKategorilerSelect = () => {
        var indents = [];
        indents.push(<option key={0} value={0}>TÜMÜ</option>);
        for (let i = 0; i < altKategoriler.length; i++) {
            const element = altKategoriler[i];
            indents.push(<option key={element.AltKategoriId} value={element.AltKategoriId}>{element.AltKategoriAd}</option>);
        }
        return indents;
    }
    const buyil = getYears();
    const history = useHistory();
    const addFormData = (key, value) => {
        const ff = structuredClone(form)
        ff[key] = value;
        setForm(ff);
        //birime bağlı kategorileri getir
        if (key == "Birimler") {
            fetch(variables.API_URL + 'istatistik/Kategori/' + value, {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => res.json())
                .then(json => {
                    setKategoriler(json)
                })
                .catch(err => {
                    console.log(err);
                });
        }
        if (key == "Kategoriler") {
            fetch(variables.API_URL + 'istatistik/AltKategori/' + value, {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => res.json())
                .then(json => {
                    setAltKategoriler(json)
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }
    async function getTablo(AltKategoriId, KategoriId, BirimId) {
        const istek = await fetch(variables.API_URL + 'istatistik/rapor/' + form.YilBas + '/' + form.YilBit + '/' + (BirimId ?? form.Birimler) + '/' + (KategoriId ?? form.Kategoriler ?? 0) + '/' + (AltKategoriId ?? form.AltKategoriler ?? 0), {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        if (await istek.ok) {
            return istek.json();
        } else {
            let txt = await istek.text();
            alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            history.push("/admin/giris");
        }
        // .then((res) => res.json())
        // .then(json => {
        //     return json
        // })
        // .catch(err => {
        // });
        return istek.json();
    }

    const getBirimler = () => {
        fetch(variables.API_URL + 'istatistik/birimler', {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((res) => res.json())
            .then(json => {
                setBirimler(json);
            })
            .catch(err => {
                console.log(err);
            });

    }
    async function getChartData(AltKategoriId) {
        const res = await fetch(variables.API_URL + 'istatistik/GetChartData/' + AltKategoriId, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((res) => { return res.json() })
            // .then(json => {
            //     return json;
            //     //setTablo(json);
            //     // console.log(json, "json");
            // })
            .catch(err => {
                console.log(err);
            });
        return res;
    }

    const chartModal = (elem) => {
        const dt = structuredClone(chartDatas);
        const lbls = [];
        const sdts = [];
        getTablo(elem.AltKategoriId,elem.KategoriId, elem.BirimId).then(CC => {
            for (let i = 0; i < CC.length; i++) {
                dt.options.plugins.title.text = CC[i].Birimler;
                dt.data.datasets[0].label = elem.Kategori + " > " + elem.AltKategori;
                sdts.push(CC[i].Istatistik)
                lbls.push(CC[i].Yil)
            }
            dt.data.labels = lbls;
            dt.data.datasets[0].data = sdts;
            setChartDatas(dt);
            setShowModal(true);

        })
    }
    React.useEffect(() => {
        getTablo().then(C => setTablo(C))
        //setTablo()
    }, [form])
    React.useEffect(() => {
        getBirimler();
    }, [])
    return (
        <>
            <Container fluid>
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">İstatistik Rapor</Card.Title>
                                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                {/* <IstatistikRaporFilterRow addFormData={addFormData} buyil={buyil} form={form}  birimler={fillBirimlerSelect}></IstatistikRaporFilterRow> */}
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Yıl Başlangıç:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={form?.YilBas}
                                                onChange={(e) => { addFormData("YilBas", e.target.value); }}>
                                                {buyil}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Yıl Bitiş:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={form?.YilBit}
                                                onChange={(e) => { addFormData("YilBit", e.target.value); }}>
                                                {buyil}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Birim:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={form?.Birimler}
                                                onChange={(e) => { addFormData("Birimler", e.target.value); }}>
                                                {fillBirimlerSelect()}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {
                                        kategoriler.length > 0 ?
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><b>Kategori:</b></Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={form?.Kategoriler}
                                                        onChange={(e) => { addFormData("Kategoriler", e.target.value); }}>
                                                        {fillKategorilerSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            : <></>
                                    }
                                    {
                                        altKategoriler.length > 0 ?
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><b>Alt Kategori:</b></Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={form?.AltKategoriler}
                                                        onChange={(e) => { addFormData("AltKategoriler", e.target.value); }}>
                                                        {fillAltKategorilerSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            : <></>
                                    }
                                </Row>
                                <br></br>
                                {/* {
                                    kategoriler.length > 0 ?
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><b>Kategori:</b></Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={form?.Kategoriler}
                                                        onChange={(e) => { addFormData("Kategoriler", e.target.value); }}>
                                                        {fillKategorilerSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><b>Alt Kategori:</b></Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={form?.AltKategoriler}
                                                        onChange={(e) => { addFormData("AltKategoriler", e.target.value); }}>
                                                        {fillAltKategorilerSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>

                                            </Col>
                                            <br></br>
                                        </Row>
                                        :
                                        <></>
                                } */}
                                <Row>
                                    <Col>
                                        <DownloadTableExcel
                                            filename="İstatistik Rapor"
                                            sheet="istatistik"
                                            currentTableRef={tableRef.current}
                                        >

                                            <button> Excel'e aktar </button>

                                        </DownloadTableExcel>
                                        {tablo.length >= 0 ? " " + tablo?.length + " adet kayıt bulundu.":""}
                                        {
                                            tablo.length > 0 ?
                                                <span className="colorRed"> Toplam {tablo.reduce((a, b) => a + (b["Istatistik"] || 0), 0)}</span>
                                                : null
                                        }

                                        <table ref={tableRef} className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Birim</th>
                                                    <th>Kategori</th>
                                                    <th>AltKategori</th>
                                                    <th>İstatistik</th>
                                                    <th>Yıl</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tablo != undefined && tablo?.length >= 0
                                                        ?
                                                        tablo.map((elem, index) => (
                                                            <tr className="cursor" key={elem.IstatistikId} onClick={() => chartModal(elem)}>
                                                                <td>{elem.Birimler}</td>
                                                                <td>{elem.Kategori}</td>
                                                                <td>{elem.AltKategori}</td>
                                                                <td>{elem.Istatistik}</td>
                                                                <td>{elem.Yil}</td>
                                                            </tr>
                                                        ))
                                                        : null
                                                }

                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                showModal ?
                    <Modal size="xl" show={true} backdrop="static" onHide={() => modalKapat()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Rapor</Modal.Title>

                        </Modal.Header>
                        <Modal.Body>
                            <Form id="formEgitimEkle" className="was-validated">
                                <Bar className="barchartsize" options={chartDatas.options} data={chartDatas.data} />
                            </Form>
                        </Modal.Body>
                        <Row>
                            <Col>

                                <Button onClick={() => modalKapat()} variant="btn btn-secondary w-100" >
                                    Kapat
                                </Button>
                            </Col>
                        </Row>
                    </Modal>
                    :
                    <></>
            }
        </>
    );
}

export default IstatistikRapor;
