import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import {
    Button,
    Card,
    Modal, Form,
    Table,
    Container,
    Row,
    Col, Tooltip,
    ListGroup, Spinner, OverlayTrigger
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { DownloadTableExcel } from 'react-export-table-to-excel';
 

function EtoKaliteDegerlendirmeAnketi() {
    const history = useHistory();
    const [guncelle, setGuncelle] = React.useState(false);
    const [detayRow, setDetayRow] = React.useState([]);
    const [detay, setDetay] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [donusTipleri, setDonusTipleri] = React.useState([{ "key": 1, "val": "Memnuniyet" }, { "key": 2, "val": "Talep" }, { "key": 3, "val": "Şikayet(düşük)" }, { "key": 4, "val": "Şikayet(normal)" }, { "key": 5, "val": "Şikayet(yüksek)" }, { "key": 6, "val": "Öneri" }, { "key": 7, "val": "Bilgi" }]);
    const [bildirimDurumlari, setbildirimDurumlari] = React.useState([{ "key": 1, "val": "Geri Bildirim Alındı" }, { "key": 2, "val": "İşlem Devam Ediyor" }, { "key": 3, "val": "İşlem Beklemede" }, { "key": 4, "val": "Yöneticide" }, { "key": 5, "val": "DF Açıldı" }, { "key": 6, "val": "Mutabakat Sağlanamadı" }, { "key": 7, "val": "Dış Kaynaklara Yönlendirildi" }, { "key": 8, "val": "Kapatıldı" }, { "key": 9, "val": "Sonuçlandırıldı" }]);
    const initialForm = { "DonusTipi": "0", "Kisi": "", "Mail": "", "Personel": 0, "OdaSicil": "", "Tarih": "2023-01-01", "Tel": "" }
    const yesilDurumlar = [7, 8, 9];
    const yesilDonusTipDurumlar = [1];
    const [form, setForm] = React.useState(initialForm);
    const [filtre, setFiltre] = React.useState({ "bildirimTipi": 0, "bildirimDurumu": 0, "tad": "GetUyeTalep" });
    const [showModal, setShowModal] = React.useState(false);
    const [atananPersonel, setatananPersonel] = React.useState([]);
    // const [showPersonelModal, setshowPersonelModal] = React.useState(false);
    const tableRef = useRef(null);

    const token = secureLocalStorage.getItem('token');
    
    const notificationAlertRef = React.useRef(null);
    const notify = (place, textt, typee) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {textt}
                    </div>
                </div>
            ),
            type: typee ?? type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    
    const getDataFiltered = async () => {
        
        (async () => {
            try {
                const istek = await fetch(variables.API_URL + 'Page/', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Tad': 'GetKaliteAnketDegerlendirme',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },                    
                    body: JSON.stringify({ "tad": "GetEtoKaliteDegerlendirmeAnketi" })
                });
                setData(await istek?.json());

            } catch (error) {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
                history.push("/admin/giris");
            }

            //const content = await rawResponse.json();
        })();
    }
    const addFormData = (key, value) => {
        const ff = structuredClone(form)
        ff[key] = value;
        setForm(ff);
    }
    const talepDetayGetir = (talepId) => {
        (async () => {
            const istek = await fetch(variables.API_URL + 'Page', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Tad': 'GetUyeTalepDetay',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ "uyetalepid": talepId, "tad": "GetUyeTalepDetay" })
            });
            if (await istek.ok) {
                //console.log(await istek?.json());
                setDetay(await istek?.json());
            } else {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            }
        })();
    }
    const duzenle = (elem) => {
        talepDetayGetir(elem.Id);
        setGuncelle(true);
        setShowModal(true);
        setForm(elem);
    }

    const formKaydet = () => {
        // console.log(form, "form");
         
    }
    const formGuncelle = () => {

        // const ff = structuredClone(form);
        // ff.tad = "UyeTalep";
        // (async () => {
        //     const rawResponse = await fetch(variables.API_URL + 'page', {
        //         method: 'put',
        //         headers: {
        //             'Accept': 'application/json, text/plain, */*',
        //             'Tad': 'UyeTalep',
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         },
        //         body: JSON.stringify(ff)
        //         //body: form
        //     });
        //     const content = await rawResponse.json();
        //     modalKapat();
        //     //getData();
        //     getDataFiltered();
        //     notify("tc", "Güncelleme işlemi başarılı", "success")
        // })();
    }
    const formSil = (id) => {
        alert("Silme işlemi yapılamaz!!");
        return;
        if (confirm("Silmek istediğinizden emin misiniz?")) {
            // console.log(id, "id");
            //const ff = structuredClone(form);
            const rr ={};
            rr.tad = "UyeTalep";
            rr.Id = id;
            (async () => {
                const rawResponse = await fetch(variables.API_URL + 'Page/', {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Tad': 'UyeTalep',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    //headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(rr)
                    //body: form
                });
                //const content = await rawResponse.json();
                modalKapat();
                //getData();
                getDataFiltered();
                notify("tc", "Silme işlemi başarılı", "success")
            })();
        }
    }

    function modalKapat() {
        setShowModal(false);
        setGuncelle(false);
        //setForm({});
    }
     
    // React.useEffect(() => {
    //     //getData()
    //     getDataFiltered();
    // }, [])
    // React.useEffect(() => {
    //     console.log(`data`, data);
    // }, [data])
    React.useEffect(() => {
        getDataFiltered();
    }, [filtre])


    return (
        <>
            <Container fluid>
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                               
                                {/* <DownloadTableExcel
                                    filename="ÜyeBildiriTablo"
                                    sheet="ÜyeBildirim"
                                    currentTableRef={tableRef.current}
                                >
                                    <Button variant="secondary"> Excel'e aktar </Button>
                                </DownloadTableExcel> */}
                                <span> {data?.length + " adet kayıt bulundu!"}</span>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                {/* <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Bildirim Tipi:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={filtre?.DonusTipi}
                                                onChange={(e) => { setFiltre({ ...filtre, bildirimTipi: e.target.value }) }}>
                                                {fillDonusTipiSelect()}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Bildirim Durumu:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={filtre?.bildirimDurumu}
                                                onChange={(e) => { setFiltre({ ...filtre, bildirimDurumu: e.target.value }) }}>
                                                {fillBildirimDurumuSelect()}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row> */}
                                <Row className="overflow-x-scroll">
                                    {/* <Table ref={tableRef} className="table-hover table-striped uyeTalepTable table-layout-fixed table-td-width-auto"> */}
                                    <Col>
                                    <Table ref={tableRef} className="table-hover table-striped uyeTalepTable table-td-width-auto  ">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Ad Soyad</th>
                                                <th>Üye Not</th>
                                                <th>Memnuniyet</th>
                                                <th>Cep</th>
                                                <th>Eposta</th>
                                                <th className="w20percentXXX  ">Hizmet Birim</th>
                                                <th>Kayıt Tarihi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data != undefined > 0
                                                    ? data?.map((elem, index) => {
                                                        return (
                                                            <tr key={index} className={(yesilDurumlar.some(D => D == elem.BildirimDurumu) || yesilDonusTipDurumlar.some(D => D == elem.DonusTipi)) ? "bgGreen" : "bgRed"}>
                                                                <td>{elem.Id}{" "}
                                                                    {/* <span title={"Güncelle/Düzenle"} className="cursor colorGreen">
                                                                        <svg onClick={() => { duzenle(elem) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                        </svg>
                                                                    </span> */}
                                                                    <span title={"Sil!"} className="cursor colorRed">
                                                                        <svg onClick={() => { formSil(elem.Id) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                                <td>{elem.AdSoyad}</td>
                                                                <td className=" ">
                                                                    <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-${'left'}`}>{elem.UserNote != "" ? elem.UserNote : "-"}</Tooltip>}>
                                                                        <div className=" dotdotdot" >{elem.UserNote != "" ? elem.UserNote : "-"}</div>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td><span title={""}>{elem.Memnuniyet}</span></td>
                                                                <td><span title={"Görüşülen Kişi"}>{elem.Cep}</span></td>
                                                                <td>
                                                                    {elem.Eposta}
                                                                </td>
                                                                <td>{elem.HizmetBirim}</td>
                                                                <td>{elem.KayitTarih.split('T')[0] } {elem.KayitTarih.split('T')[1] }</td>
                                                                {/* <td>{elem.Tarih?.split('T')[0]}</td> */}
                                                                 

                                                            </tr>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </Table>
                                    </Col>
                                    
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container >
        </>
    );
}

export default EtoKaliteDegerlendirmeAnketi;
