import React from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import { useHistory } from "react-router-dom";
import { Button,OverlayTrigger, Card, Modal, Form, Table, Container, Tooltip, ButtonGroup, Row, Col, Spinner } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { gecenGun, gecenGunDakika, kalanGun } from "../helpers/generic";


function PersonelinTalepleri() {
  const history = useHistory(); 
  const [data, setData] = React.useState([]); 
  const [personelinTalebiForm, setpersonelinTalebiForm] = React.useState({ "show": false, "Talep": "" });
 

  const token = secureLocalStorage.getItem('token');
  const notificationAlertRef = React.useRef(null);
  const notify = (place, textt, typee) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {textt}
          </div>
        </div>
      ),
      type: typee ?? type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getData = async () => {
    const istek = await fetch(variables.API_URL + 'page/PersonelinTalepleriGet', {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Tad': 'PersonelinTalepleriGet',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
    if (istek.ok) {
      setData(await istek.json());
    } else {
      let txt = await istek.text();
      alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      history.push("/admin/giris");
    }
  }
  const personelinTalebiFormKaydet = () => {
    const ff = structuredClone(personelinTalebiForm);
    delete ff.show;
    ff.tad = "PersonelTalepInsert";
    (async () => {
      const rawResponse = await fetch(variables.API_URL + 'Page', { method: 'POST', headers: { 'Accept': 'application/json, text/plain, */*', 'Tad': 'PersonelTalepInsert', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }, body: JSON.stringify(ff) });
      !rawResponse.ok ? alert("İşlem sırasında hata! Yetkiniz olmayabilir.") : null;
      const content = await rawResponse.json();
      notify("tc", "Talebiniz iletilmiştir. Teşekkür ederiz !", "success");
      setpersonelinTalebiForm({ ...personelinTalebiForm, "show": false, "Talep": ""  })
      getData()
    })();
  }
  const changeHandler = (e) => {
    //onChange={(e) => setpersonelinTalebiForm({ ...personelinTalebiForm, "Talep": e.target.value?.toLocaleUpperCase('tr-TR') })}
    setpersonelinTalebiForm((prev) => ({
     ...prev,
      [e.target.name]: e.target.value?.toLocaleUpperCase('tr-TR')//upper case sona gönderiyor

    })) 
  }
  React.useEffect(() => {
    getData()
  }, []) 
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title as="h4">Performans Gelişimlerim</Card.Title> */}
                <Button variant="secondary" >Personelin Talepleri</Button>
                <Button className="arrow bounce" onClick={() => setpersonelinTalebiForm({ ...personelinTalebiForm, "show": true })} variant="secondary" >Talep Girişi!</Button>
                <span> {data?.length + " adet kayıt bulundu!"}</span>
                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Personel Ad</th>
                      <th>Personelin Talebi</th>
                      <th>Talep Tarihi</th>
                      <th>Durum</th>
                      <th>Durum Tarihi</th>
                      <th>Yetkili Notu</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > -1
                        ? data.map((elem, index) => {
                          return (
                            <tr key={index}>
                              {/* <td>{elem.EgitimId}{" "}</td> */}
                              <td>{elem?.Id}{" "}</td>
                              <td>{elem?.PersonelAd}</td>
                              <td>
                                <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-${'left'}`}>{elem?.Talep != "" ? elem?.Talep : "-"}</Tooltip>}>
                                  <div className=" dotdotdot" >{elem?.Talep != "" ? elem?.Talep : "-"}</div>
                                </OverlayTrigger>
                              </td>
                              <td>{elem?.KaydolmaTarihi?.replace("T", " ")}</td>
                              <td>{elem?.Durum == null ? "Değerlendiriliyor":(elem?.Durum==true?"Olumlu":"Olumsuz")}</td>
                              <td>{elem?.DurumTarihi?.replace("T", " ")}</td>
                              <td>{elem?.YetkiliNotu}</td>

                            </tr>
                          )
                        })
                        : <tr><td>{data?.length + " adet kayıt bulundu!"}<Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" /></td></tr>
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {
            personelinTalebiForm?.show ?
              //onChange={(e) => { setDetayRow({ ...detayRow, Tarih: e.target.value }) }}                        
              <Modal size="xl" show={true} backdrop="static" onHide={() => setpersonelinTalebiForm({ ...personelinTalebiForm, "show": false })}>
                <Modal.Header closeButton>
                  <Modal.Title>Talep ve önerilerinizi bu alanı kullanarak Genel Sekreterliğe iletebilirsiniz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form id="formEgitimEkle" className="was-validated">
                    <Form.Group className="mb-3">
                      <Form.Label>Talebinizi detaylı, açık bir dille iletiniz:</Form.Label>
                      <Form.Control
                        // type="text"
                        as="textarea"
                        rows={3}
                        name="Talep"
                        required
                        key={"textarea1"}
                        autoFocus={false}
                        value={personelinTalebiForm?.Talep}
                        //onChange={changeHandler}
                        onChange={(e) => setpersonelinTalebiForm({ ...personelinTalebiForm, "Talep": e.target.value?.toLocaleUpperCase('tr-TR') })}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Row>
                  <Col>
                    <Button id="formKaydet" onClick={personelinTalebiFormKaydet} variant="btn btn-primary mw50" >
                      Kaydet
                    </Button>
                    <Button onClick={() => setpersonelinTalebiForm({ ...personelinTalebiForm, "show": false })} variant="btn btn-secondary mw50" >
                      Kapat
                    </Button>
                  </Col>
                </Row>
              </Modal>
              :
              <></>
          }
          
          
        </Row>
      </Container>
    </>
  );
}

export default PersonelinTalepleri;
