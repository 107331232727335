import React from "react";

import Egitim from "views/Egitim.js";
import Egitimlerim from "views/Egitimlerim.js";
import PersonelinTalepleri from "views/PersonelinTalepleri.js";
import PersonelinTalepleriRapor from "views/PersonelinTalepleriRapor.js";
import PersonelEgitimlerim from "views/PersonelEgitimlerim.js";
import PerformansDegerlendirme from "views/PerformansDegerlendirme.js";
import Istatistik from "views/Istatistik.js";
import IstatistikRapor from "views/IstatistikRapor.js";
import Giris from "views/Giris.js";
import Page from "views/Page.js";
import UyeTalep from "views/UyeTalep.js";
import UyeTaleplerim from "views/UyeTaleplerim";
import EtoKaliteDegerlendirmeAnketi from "views/EtoKaliteDegerlendirmeAnketi";

import { PersonCircle, BarChartSteps, RightTurn, ClipboardCheck } from "./helpers/icons";

// auth: bilgileri kullanıcının sayfayı görebilmesi için veritabanından ayarlanmaktadır
const dashboardRoutes = [
  {
    auth: "/api/egitim/get",
    parent: "Performans Gelişimi",
    path: "/egitim",
    name: "Program Oluştur",
    icon: "nc-icon nc-circle-09",
    iconComp: <PersonCircle />,
    component: Egitim,
    layout: "/admin"
  },
  {
    auth: "/api/page/uyetalep/get",
    parent: "Üye Geri Bildirim",
    path: "/UyeTalep",
    name: "Rapor",
    icon: "nc-icon nc-alien-33",
    iconComp: <ClipboardCheck />,
    component: UyeTalep,
    layout: "/admin"
  },
  {
    auth: "/api/page/uyetaleplerim/get",
    parent: "Üye Geri Bildirim",
    path: "/UyeTaleplerim",
    name: "Üye Geri Bildirimlerim",
    icon: "nc-icon nc-alien-33",
    component: UyeTaleplerim,
    layout: "/admin"
  },
  {
    auth: "/api/page/etokalitedegerlendirmeanketi/get",
    parent: "Üye Geri Bildirim",
    path: "/EtoKaliteDegerlendirmeAnketi",
    name: "Eto Kalite Değerlendirme Anketi",
    icon: "nc-icon nc-alien-33",
    component: EtoKaliteDegerlendirmeAnketi,
    layout: "/admin"
  },
  {
    auth: "/api/page/egitimlerim/get",
    parent: "Performans Gelişimi",
    path: "/egitimlerim",
    name: "Gelişim Programlarım",
    icon: "nc-icon nc-circle-09",
    component: Egitimlerim,
    layout: "/admin"
  },
  {
    auth: "/api/page/personelintalepleri/get",
    parent: "Personelin Talepleri",
    path: "/PersonelinTalepleri",
    name: "Personelin Talepleri",
    icon: "nc-icon nc-circle-09",
    iconComp: <PersonCircle />,
    component: PersonelinTalepleri,
    layout: "/admin"
  },
  {
    auth: "/api/page/personelintaleplerirapor/get",
    parent: "Personelin Talepleri",
    path: "/PersonelinTalepleriRapor",
    name: "Personelin Talepleri Rapor Ekranı",
    icon: "nc-icon nc-circle-09",
    iconComp: <PersonCircle />,
    component: PersonelinTalepleriRapor,
    layout: "/admin"
  },
  {
    auth: "/api/page/personelleriminegitimleri/get",
    parent: "Performans Gelişimi",
    path: "/personelegitimlerim",
    name: "Personellerimin Programları",
    icon: "nc-icon nc-circle-09",
    component: PersonelEgitimlerim,
    layout: "/admin"
  },
  // {
  //   auth: "/api/page/performansdegerlendirme/get",
  //   parent: "Performans Gelişimi",
  //   path: "/performansdegerlendirme",
  //   name: "Performans Değerlendirme",
  //   icon: "nc-icon nc-circle-09",
  //   component: PerformansDegerlendirme,
  //   layout: "/admin"
  // },
  {
    auth: "/api/istatistik/birimler",
    parent: "İstatistik",
    path: "/istatistik",
    name: "İstatistik",
    icon: "nc-icon nc-alien-33",
    iconComp: <BarChartSteps />,
    component: Istatistik,
    layout: "/admin"
  },
  {
    auth: "/api/istatistik/rapor",
    parent: "İstatistik",
    path: "/istatistikrapor",
    name: "İstatistik Rapor",
    icon: "nc-icon nc-alien-33",
    component: IstatistikRapor,
    layout: "/admin"
  },
  {
    auth: "1",
    parent: "Giriş/Çıkış",
    path: "/giris",
    name: "Giriş/Çıkış",
    icon: "nc-icon nc-alien-33",
    iconComp: <RightTurn />,
    component: Giris,
    layout: "/admin"
  },
  // {
  //   auth: "1",
  //   parent: "Giriş/Çıkış",
  //   path: "/Page/Dnm3",
  //   name: "Page",
  //   icon: "nc-icon nc-alien-33",
  //   component: Page,
  //   layout: "/admin"
  // },
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-alien-33",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin"
  // },

  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // }
];
export const dashboardRoutesParents = [
  {
    parent: "Performans Gelişimi",
  },
  {
    parent: "Personelin Talepleri",
  },
  {
    parent: "İstatistik",
  },
  {
    parent: "Üye Geri Bildirim",
  },
  {
    parent: "Giriş/Çıkış",
  },
];

export default dashboardRoutes;
