import React, { useContext, useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { variables } from "helpers/Variables";
import secureLocalStorage from "react-secure-storage";
import { Accordion, Nav, Dropdown, DropdownButton } from "react-bootstrap";
import logo from "assets/img/reactlogo.png";

import { ThemeContext, ThemeProvider } from '../../helpers/SidebarContext';


function Sidebar({ color, image, routes, parents }) {
    //const [token, setToken] = useState(secureLocalStorage.getItem('token') ?? []);
    const token = secureLocalStorage.getItem('token') ?? [];
    const { theme } = useContext(ThemeContext);
    const [auth, setAuth] = React.useState([]);
    // const [logoText, setLogoText] = useState('eto.net');
    // const [authCtx, setAuthCtx] = useState(["asd"]);


    const location = useLocation();
    const activeRoute = (routeName) => {
        return location.pathname == routeName ? "active" : "";
        //return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    let listed = [];
    const [calculated, setcalculated] = React.useState([]);
    const getAuth = (param) => {
        if (param == null || param == undefined) {
            setAuth([]);
            return;
        }
        fetch(variables.API_URL + 'page/GetAuth', { method: 'get', headers: { 'Accept': 'application/json, text/plain, */*', 'Tad': 'GetAuth', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + param ?? token }, })
            .then((res) => res.json())
            .then(json => {
                //console.log(json);
                setAuth(json);
            })
            .catch(C => {
                console.log(C);
                setAuth([])
            })
    }
    React.useEffect(() => {
        let gg = []
        routes.forEach(el => {
            if (el.auth == "1" || auth.some(C => C.YetkiSayfa == el.auth) || auth.some(C => C.YetkiSayfa == "admin")) {
                gg.push(el);
            }
        });
        setcalculated(gg)
    }, [auth])
    React.useEffect(() => {
        getAuth(secureLocalStorage.getItem('token'));
    }, [])
    React.useEffect(() => {
        getAuth(secureLocalStorage.getItem('token'));
    }, [theme])
    return (
        <div className="sidebar" data-image={image} data-color={color}>
            <div
                className="sidebar-background"
                style={{
                    backgroundImage: "url(" + image + ")"
                }}
            />
            <div className="sidebar-wrapper">
                <div className="logo d-flex align-items-center justify-content-start">
                    <a
                        href={variables.THIS_APP_URL}
                        className="simple-text logo-mini mx-1"
                    >
                        <div className="logo-img">
                            <img src={require("assets/img/favicon.ico")} alt="..." />
                        </div>
                    </a>
                    <a className="simple-text" href="http://etomanager.etonet.org.tr">
                        ETOMANAGER
                        {/* {JSON.stringify(theme)} */}
                    </a>
                </div>
                <Nav>
                    < Accordion>
                        {calculated.map((prop, key) => {
                            if (!prop.redirect && prop.parent && !listed.includes(prop.parent)) {
                                listed.push(prop.parent)
                                return (
                                    <Accordion.Item
                                        eventKey={key}
                                        className={` ${prop.upgrade ? "active active-pro asdasd" : activeRoute(prop.layout + prop.path)}`}
                                        key={key}
                                    >
                                        <Accordion.Header className="nav-link myAcordion fs20 lh20">
                                            {prop.iconComp != undefined ? prop.iconComp : <i className={prop.icon} />}
                                            <span> </span>
                                            {prop.parent}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                calculated.map((el, ind) => {
                                                    if (el.parent == prop.parent) {
                                                        return (
                                                            <NavLink
                                                                key={ind}
                                                                to={el.layout + el.path}
                                                                className="nav-link m-0 p-1"
                                                                activeClassName="active"
                                                            >
                                                                {/* <i className={el.icon}>> </i> */}
                                                                {/* <p>&gt; {el.name}</p> */}
                                                                <p>
                                                                    {/* {prop.iconComp != undefined ? prop.iconComp : <i className={prop.icon} />} */}
                                                                    {prop.iconComp ?? <>-</> }
                                                                    {el.name}
                                                                </p>
                                                            </NavLink>
                                                        )
                                                    }
                                                })
                                            }

                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            } else {
                                return null;

                            }
                        })}
                    </Accordion>
                </Nav>
            </div >
        </div >
    );
}

export default Sidebar;
