import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import { useHistory } from "react-router-dom";
import { Button, Badge, Card, Modal, Form, Table, Container, ButtonToolbar, ButtonGroup, Row, Col, Spinner } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { gecenGun, kalanGun } from "../helpers/generic";


function PersonelEgitimlerim() {
    const history = useHistory();
    const [kaydetme, setKaydetme] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [form, setForm] = React.useState({});

    const [aktifPersonel, setaktifPersonel] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const [personelAnketSave, setpersonelAnketSave] = React.useState(false);
    const [showAnketModal, setshowAnketModal] = React.useState(false);
    const [anketSorulari, setanketSorulari] = React.useState([]);

    const token = secureLocalStorage.getItem('token');
    const notificationAlertRef = React.useRef(null);
    const notify = (place, textt, typee) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {textt}
                    </div>
                </div>
            ),
            type: typee ?? type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const getData = async () => {
        const istek = await fetch(variables.API_URL + 'page/PersonelleriminEgitimleri', {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Tad': 'PersonelleriminEgitimleri',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        if (istek.ok) {
            setData(await istek.json());
        } else {
            let txt = await istek.text();
            alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            history.push("/admin/giris");
        }
    }
    const getAnketData = (elem) => {
        //const dt = structuredClone(detayRow);
        console.log("elem",elem)
        var dt = {};
        dt.tad = "AnketlerGet";
        dt.anket = "EgitimSonrasiAnketPersonel";
        dt.EgitimId = elem?.EgitimId;
        dt.k_no = elem?.PersonelId;
        (async () => {
            const istek = await fetch(variables.API_URL + 'Page', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Tad': 'AnketlerGet',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(dt)
            });
            if (await istek.ok) {
                //console.log(await istek?.json());
                setanketSorulari(await istek?.json());

                //talepDetayGetir(form.Id);
                //notify("tc", "Kayıt işlemi başarılı", "success");
            } else {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            }
        })();
    }
    const getAnketDataAmir = (elem) => {
        //const dt = structuredClone(detayRow);
        //console.log("elem",elem)
        var dt = {};
        dt.tad = "AmirAnketlerGet";
        dt.anket = "EgitimSonrasiAnketAmir";
        dt.EgitimId = elem?.EgitimId;
        dt.k_no = elem?.PersonelId;
        (async () => {
            const istek = await fetch(variables.API_URL + 'Page', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Tad': 'AmirAnketlerGet',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(dt)
            });
            if (await istek.ok) {
                //console.log(await istek?.json());
                setanketSorulari(await istek?.json());

                //talepDetayGetir(form.Id);
                //notify("tc", "Kayıt işlemi başarılı", "success");
            } else {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            }
        })();
    }
    const addFormData = (key, value) => {
        const ff = structuredClone(form)
        ff[key] = value;
        setForm(ff);
    }
    function modalKapat() {
        setShowModal(false);
        setKaydetme(false);
        setForm({});
    }
    function anketModalAcAmir(elem: never): void {
        //getEgitimeKatilanPersonel(elem.Id)
        //setanketSorulari([]);
        //console.log("anketModalAc", elem)
        setaktifPersonel(elem.PersonelId);
        getAnketDataAmir(elem);
        setKaydetme(false);
        setshowAnketModal(true);
        setForm(elem);
        return;
    }
    function anketModalAc(elem: never): void {
        setpersonelAnketSave(false);
        setaktifPersonel(elem.PersonelId);
        getAnketData(elem);
        setKaydetme(true);
        setshowAnketModal(true);
        setForm(elem);
        console.log(elem);
        return;
    }
    const formGuncelle = () => {
        (async () => {
            const rawResponse = await fetch(variables.API_URL + 'egitim', {
                method: 'Put',
                headers: { 'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify(form)
                //body: form
            });
            const content = await rawResponse.json();
            modalKapat();
            getData();
            notify("tc", "Güncelleme işlemi başarılı", "success")
        })();
    }

    function anketModalKapat() {
        setshowAnketModal(false);
        setanketSorulari([]);
        setpersonelAnketSave(true); 
    }
    function anketDegerGir(elem: never, Secenek1: any): void {
        const dt = structuredClone(anketSorulari);
        dt.forEach(element => {
            if (element?.Id == elem?.Id) element.KullaniciCevap = Secenek1;
        });
        setanketSorulari(dt);
    }
    function anketFormKaydet() {
        anketSorulari.forEach(e => {
            //console.log(`element`, e);
            (async () => {
                var dt = { "tad": "AnketlerAmirPost", "anketid": e?.Id, "personelid": aktifPersonel, "HrkEgitimlerId": e?.HrkEgitimlerId, "KullaniciCevap": e?.KullaniciCevap };
                const istek = await fetch(variables.API_URL + 'Page', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Tad': 'AnketlerAmirPost',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(dt)
                });
                if (await istek.ok) {
                    //console.log(await istek?.json());
                    //setDetay(await istek?.json());
                    //setanketSorulari(await istek?.json());
                    //talepDetayGetir(form.Id);
                    //notify("tc", "Kayıt işlemi başarılı", "success");
                } else {
                    let txt = await istek.text();
                    alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
                }
            })();
        });
        notify("tc", "Kayıt işlemi başarılı", "success");
    }
 
    React.useEffect(() => {
        getData()
    }, []) 
    return (
        <>
            <Container fluid>
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                {/* <Card.Title as="h4">Performans Gelişimlerim</Card.Title> */}
                                <Button variant="secondary" >Programlarım</Button>
                                
                                <span> {data?.length + " adet kayıt bulundu!"}</span>
                                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}

                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Program Adı</th>
                                            <th>Başlangıç Tarihi</th>
                                            <th>Bitiş Tarihi</th>
                                            <th>Yer / Kurum</th>
                                            <th>Anket</th>
                                            <th>Açıklama</th>
                                            <th>AdSoyad</th>
                                            <th>Katıldı</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // data.length > -1
                                            data != undefined > 0
                                                ? data.map((elem, index) => { 
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td>{elem.EgitimId}{" "}</td> */}
                                                            <td>{elem.SatirNumarasi}{" "}</td>
                                                            <td data-val={elem.EgitimId}>{elem.Program} - {elem.EgitimAdi}</td>
                                                            <td>
                                                                {elem.BasTarih.replace("T", " ")}{kalanGun(elem.BasTarih) > 0 ? <Badge className="colorWhite" bg="success" title={kalanGun(elem.BasTarih) + " gün kaldı."}>{kalanGun(elem.BasTarih)}</Badge> : null}
                                                            </td>
                                                            <td>
                                                                <label>
                                                                    {elem.BitTarih.replace("T", " ")}{gecenGun(elem.BitTarih) > 0 ? <Badge className="colorWhite" bg="success" title={gecenGun(elem.BitTarih) + " gün geçti."}>{gecenGun(elem.BitTarih)}</Badge> : null}
                                                                </label>
                                                            </td>
                                                            <td><span title="Yer">{elem.Yer}</span> - <span title="Kurum">{elem.Kurum}</span></td>
                                                            <td>
                                                                {gecenGun(elem.BitTarih) > 2 ?
                                                                    <span title="Amir anket cevap" onClick={() => anketModalAcAmir(elem)} className={"cursor "+(elem.AmirAnketCevapSayi > 0 ?'colorBlue':'colorRed' )}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                                                        </svg>
                                                                    </span>
                                                                    :
                                                                    null}
                                                                {gecenGun(elem.BitTarih) > 2 ?
                                                                    <span title="Personel anket cevap" onClick={() => anketModalAc(elem)} className={"cursor "+(elem.PersonelAnketCevapSayi > 0 ?'colorBlue':'colorRed' )}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                                                        </svg>
                                                                    </span>
                                                                    :
                                                                    null}

                                                            </td>
                                                            <td>{elem.Aciklama}</td>
                                                            <td>{elem.AdSoyad}</td>
                                                            <td>
                                                                {/* {gecenGun(elem.BitTarih) > 2 ?
                                                                    <Badge className="colorWhite" bg="danger" >katılmadı</Badge>
                                                                    :
                                                                    null} */}
                                                                {gecenGun(elem.BitTarih) > 2 ?
                                                                    <Badge className="colorWhite" bg="success" >katıldı</Badge>
                                                                    :
                                                                    null}
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                                : 
                                                <tr><td>
													{/* {"Yükleniyor! "+ data?.length + " adet kayıt bulundu!"} */}
													{"Yükleniyor! "}
													<Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
												</td></tr>
                                                // <tr><td>{data?.length + " adet kayıt bulundu!"}<Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" /></td></tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    {
                        showAnketModal ?
                            <Modal size="xl" show={true} backdrop="static" onHide={() => anketModalKapat()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {form?.EgitimAdi.toLocaleUpperCase('tr-TR')} 
                                        {form?.AdSoyad != null ? <p>({form.AdSoyad})</p> : null}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="formPersonelEkle" className="was-validated">
                                        <Row>
                                            <Col>
                                                <div className="scroller100hg">
                                                    {

                                                        anketSorulari.map((elem, index) => {
                                                            console.log(elem);
                                                            return (
                                                                <div key={index}>
                                                                    <h5>{index + 1}.{elem.Soru}</h5>
                                                                    <div>
                                                                        {/* <span>Cevap: {JSON.stringify(elem)}</span> */}
                                                                        {
                                                                            elem.Tip == "YAZILI" ?
                                                                                <textarea onChange={(e) => anketDegerGir(elem, e.target.value)} className="form-control" value={elem?.KullaniciCevap ?? ""}></textarea>
                                                                                :
                                                                                <div>
                                                                                    <ButtonToolbar>
                                                                                        <ButtonGroup bssize="large">
                                                                                            <Button className={elem.KullaniciCevap == elem.Secenek1 ? " active " : ""} onClick={() => anketDegerGir(elem, elem.Secenek1)}>{elem.Secenek1}</Button>
                                                                                            <Button className={elem.KullaniciCevap == elem.Secenek2 ? " active " : ""} onClick={() => anketDegerGir(elem, elem.Secenek2)}>{elem.Secenek2}</Button>
                                                                                            <Button className={elem.KullaniciCevap == elem.Secenek3 ? " active " : ""} onClick={() => anketDegerGir(elem, elem.Secenek3)}>{elem.Secenek3}</Button>
                                                                                            <Button className={elem.KullaniciCevap == elem.Secenek4 ? " active " : ""} onClick={() => anketDegerGir(elem, elem.Secenek4)}>{elem.Secenek4}</Button>
                                                                                            <Button className={elem.KullaniciCevap == elem.Secenek5 ? " active " : ""} onClick={() => anketDegerGir(elem, elem.Secenek5)}>{elem.Secenek5}</Button>
                                                                                        </ButtonGroup>
                                                                                    </ButtonToolbar>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Modal.Body>
                                <Row>
                                    <Col>
                                        {
                                            // personelAnketSave ?

                                                kaydetme ?
                                                    <small></small>
                                                    :
                                                    <Button title="Anket sonucunu gönder" id="anketFormKaydet" onClick={anketFormKaydet} variant="btn btn-primary mw50" >
                                                        Kaydet
                                                    </Button>
                                                // : null
                                        }
                                        <Button title="Yaptığınız işlemleri kaydetmeden çıkmaya yarar!" onClick={() => anketModalKapat()} variant="btn btn-secondary mw50" >
                                            Kapat & Çık
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal>
                            :
                            <></>
                    }
                </Row>
            </Container>
        </>
    );
}

export default PersonelEgitimlerim;
