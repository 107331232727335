import React from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import { useHistory } from "react-router-dom";
import { Button,OverlayTrigger, Card, Modal, Form, Table, Container, Tooltip, ButtonGroup, Row, Col, Spinner } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { gecenGun, gecenGunDakika, kalanGun } from "../helpers/generic";


function PersonelinTalepleriRapor() {
  const history = useHistory(); 
  const [data, setData] = React.useState([]); 
  const [personelinTalebiForm, setpersonelinTalebiForm] = React.useState({ "show": false, "Talep": "",
    "Durum":0, "YetkilininNotu":"","PersonelAd":"","Id":0 });
 

  const token = secureLocalStorage.getItem('token');
  const notificationAlertRef = React.useRef(null);
  const notify = (place, textt, typee) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {textt}
          </div>
        </div>
      ),
      type: typee ?? type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getData = async () => {
    const istek = await fetch(variables.API_URL + 'page/PersonelinTalepleriRaporGet', {
      method: 'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Tad': 'PersonelinTalepleriRaporGet',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
    if (istek.ok) {
      setData(await istek.json());
    } else {
      let txt = await istek.text();
      alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      history.push("/admin/giris");
    }
  }
  const ptalepRaporFormKaydet = () => {
    const ff = structuredClone(personelinTalebiForm);
    delete ff.show;
    delete ff.PersonelAd;
    ff.tad = "PersonelTalepRaporInsert";
    
    //console.log(ff);
    //return;
    (async () => {
      const rawResponse = await fetch(variables.API_URL + 'Page', { method: 'POST', headers: { 'Accept': 'application/json, text/plain, */*', 'Tad': 'PersonelTalepRaporInsert', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }, body: JSON.stringify(ff) });
      !rawResponse.ok ? alert("İşlem sırasında hata! Yetkiniz olmayabilir.") : null;
      const content = await rawResponse.json();
      notify("tc", "Talebiniz iletilmiştir. Teşekkür ederiz !", "success");
      setpersonelinTalebiForm({ ...personelinTalebiForm, "show": false, "Talep": ""  })
      getData()
    })();
  }
  const duzenle = (elem) => {
    // console.log('elemm',elem);
    setpersonelinTalebiForm({...personelinTalebiForm, show:true
      , Id:elem?.Id
      , Talep:elem?.Talep
      , PersonelAd :elem?.PersonelAd
      , Durum :elem?.Durum
      , YetkilininNotu :elem?.YetkiliNotu ?? ""
    });

    // setaktifEgitim(elem);
    // setGuncelle(true);
    // setShowModal(true);
  };
  React.useEffect(() => {
    getData()
  }, []) 
  // React.useEffect(() => {
  //   console.log('useEffect',personelinTalebiForm);
  // }, [personelinTalebiForm]) 
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title as="h4">Performans Gelişimlerim</Card.Title> */}
                <Button variant="secondary" >Personelin Talepleri Rapor Ekranı</Button>
                {/* <Button className="arrow bounce" onClick={() => setpersonelinTalebiForm({ ...personelinTalebiForm, "show": true })} variant="secondary" >Talep Girişi!</Button> */}
                <span> {data?.length + " adet kayıt bulundu!"}</span>
                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Personel Ad</th>
                      <th>Personelin Talebi</th>
                      <th>Talep Tarihi</th>
                      <th>Durum</th>
                      <th>Durum Tarihi</th>
                      <th>Yetkili Notu</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length > -1
                        ? data.map((elem, index) => {
                          console.log(elem);
                          return (
                            <tr key={index}>
                              {/* <td>{elem.EgitimId}{" "}</td> */}
                              <td>
                                {elem?.Id}{" "}
                                <span
                                  title="Programı Düzenle"
                                  className="cursor colorGreen"
                                >
                                  <svg
                                    onClick={() => {
                                      duzenle(elem);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    />
                                  </svg>
                                </span>
                                <span
                                  title="Programı Sil??"
                                  className="cursor colorRed"
                                >
                                  <svg
                                    onClick={() => {
                                      alert('Talep silme işlemini sadece bilgi işlem yapabilir!')
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                    />
                                  </svg>
                                </span>
                              </td>
                              <td>{elem?.PersonelAd}</td>
                              <td>
                                <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-${'left'}`}>{elem?.Talep != "" ? elem?.Talep : "-"}</Tooltip>}>
                                  <div className=" dotdotdot" >{elem?.Talep != "" ? elem?.Talep : "-"}</div>
                                </OverlayTrigger>
                              </td>
                              <td>{elem?.KaydolmaTarihi?.replace("T", " ")}</td>
                              <td>{elem?.Durum == null ? "Değerlendiriliyor":(elem?.Durum==true ? "Olumlu":"Olumsuz")}</td>
                              <td>{elem?.DurumTarihi?.replace("T", " ")}</td>
                              <td>{elem?.YetkiliNotu}</td>
                            </tr>
                          )
                        })
                        : <tr><td>{data?.length + " adet kayıt bulundu!"}<Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" /></td></tr>
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {
            personelinTalebiForm?.show ?
              //onChange={(e) => { setDetayRow({ ...detayRow, Tarih: e.target.value }) }}                        
              <Modal size="xl" show={true} backdrop="static" onHide={() => setpersonelinTalebiForm({ ...personelinTalebiForm, "show": false })}>
                <Modal.Header closeButton>
                  <Modal.Title>Personel Talebi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form id="formEgitimEkle" className="was-validated">
                    <Form.Group className="mb-3">
                      <Form.Label><b>{personelinTalebiForm?.PersonelAd}</b> isimli personelin talebi:</Form.Label>
                      <Form.Control
                        // type="text"
                        as="textarea"
                        rows={3}
                        disabled
                        value={personelinTalebiForm?.Talep}
                        onChange={(e) => setpersonelinTalebiForm({ ...personelinTalebiForm, "Talep": e.target.value?.toLocaleUpperCase('tr-TR') })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Durum:</Form.Label>
                      <select className="form-control form-select" aria-label="Default select example" 
                        defaultValue={personelinTalebiForm?.Durum == true ? 1:0}
                        onChange={e=> setpersonelinTalebiForm({...personelinTalebiForm, "Durum":e.target.value})} >
                        <option value="0">Olumsuz</option>
                        <option value="1">Olumlu</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Yetkilinin Notu:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={personelinTalebiForm?.YetkilininNotu}
                        onChange={(e) => setpersonelinTalebiForm({ ...personelinTalebiForm, "YetkilininNotu": e.target.value?.toLocaleUpperCase('tr-TR') })}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Row>
                  <Col>
                    <Button id="formKaydet" onClick={ptalepRaporFormKaydet} variant="btn btn-primary mw50" >
                      Kaydet
                    </Button>
                    <Button onClick={() => setpersonelinTalebiForm({ ...personelinTalebiForm, "show": false })} variant="btn btn-secondary mw50" >
                      Kapat
                    </Button>
                  </Col>
                </Row>
              </Modal>
              :
              <></>
          }
          
          
        </Row>
      </Container>
    </>
  );
}

export default PersonelinTalepleriRapor;
