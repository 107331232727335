import React, { useRef, useState, useContext } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
//import { Link, useNavigate } from 'react-router-dom';
//import { useAuth } from '../contexts/AuthContext';
import { useHistory } from "react-router-dom";
import { variables } from "helpers/Variables";
import secureLocalStorage from "react-secure-storage";
import {  ThemeContext, ThemeProvider } from '../helpers/SidebarContext';

export default function Giris() {
    const {  handleToggleTheme } = useContext(ThemeContext);

    const girdi = secureLocalStorage.getItem("token");
    const kullaniciRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [waiting, setWaiting] = useState(false);
    //const {signin} = useAuth(); 
    const verbose = true;
    const history = useHistory();
    async function submitHandler(e) {
        e.preventDefault()
        setWaiting(true)

        const login = { 'Email': kullaniciRef.current.value, 'Password': passwordRef.current.value };
        (async () => { 
            const rawResponse = await fetch(variables.API_URL + 'token', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(login)
            });
            if (rawResponse.ok == false) {
                alert("Giriş bilgilerini kontrol ediniz!")
                return;
            }
            const token = await rawResponse.text();

            secureLocalStorage.setItem("token", token);
            history.push("/admin/egitimlerim");
            getAuth(token);

        })();
         
        // try {
        //     //signin(emailRef.current.value, passwordRef.current.value)


        // } catch (err) {
        //     setError(verbose ? err.message : 'Failed created the account')
        // }
        setWaiting(false)
    }
    const getAuth = (token) => { 
        fetch(variables.API_URL + 'page/GetAuth', {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*', 
                'Tad': 'GetAuth', 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((res) => res.json())
            .then(json => {
                handleToggleTheme(json)
            })
            .catch(C => console.log(C))
    }

    return (
        <>
            {
                girdi ?
                    <Card>
                        <Card.Body>

                            {/* <button onClick={switchLogoText}>Switch Logo Text</button> */}
                            <h3 className='text-center mb-2'>Hoş Geldiniz!</h3>
                            <h3 className='text-center mb-2'>Çıkış Yapmak İçin <a href="#" onClick={e => {secureLocalStorage.removeItem("token");handleToggleTheme("0")}}>Tıklayınız!</a></h3>
                            {error && <Alert variant='danger'>{error}</Alert>}
                        </Card.Body>
                    </Card>
                    :
                    <Card>
                        <Card.Body>
                            <h3 className='text-center mb-2'>Giriş</h3>
                            {error && <Alert variant='danger'>{error}</Alert>}
                            <Form onSubmit={submitHandler}>
                                <Form.Group id='kullanici'>
                                    <Form.Label>Kullanici</Form.Label>
                                    <Form.Control type='text' ref={kullaniciRef} required></Form.Control>
                                </Form.Group>
                                <Form.Group id='password'>
                                    <Form.Label>Şifre</Form.Label>
                                    <Form.Control type='password' ref={passwordRef} required></Form.Control>
                                </Form.Group>
                                <Button type='submit' className='w-100 mt-2' disabled={waiting}>Giriş</Button>
                            </Form>
                        </Card.Body>
                    </Card>
            }
        </>);
}