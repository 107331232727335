// import React from "react";
// import { Form } from "react-bootstrap";

export const setMe = (stateObj, e) => {
  let dat = structuredClone(stateObj);
  dat[e.target.name] = e.target?.checked ?? e.target?.value ?? e?.value ?? ""; 
  return dat;
};

export const gecenGun = (bitisTarihi) => {
  var date1 = new Date();
  var date2 = new Date(bitisTarihi?.split("T")[0]);
  var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24));
  if (diffDays < 0) diffDays = "?";
  return diffDays;
}

export const kalanGun = (basTarihi) => {
  var bugun = new Date();
  var date2 = new Date(basTarihi?.split("T")[0]);
  var diffDays = parseInt((date2 - bugun) / (1000 * 60 * 60 * 24));
  if (diffDays < 0) diffDays = "?";
  return diffDays;
}
export const gecenGunDakika = (bitisTarihi : Date) => {
  var date1 = new Date();
  // var date2 = new Date(bitisTarihi.split("T")[0]);
  var date2 = new Date(bitisTarihi?.toLocaleString());
  var diffDays = parseInt((date1 - date2) / (1000 * 60 ));
  if (diffDays < 0) diffDays = "?";
  return diffDays;
}