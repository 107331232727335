import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import {
    Button,
    Card,
    Modal, Form,
    Table,
    Container,
    Row,
    Col, Tooltip,
    ListGroup, Spinner, OverlayTrigger
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { DownloadTableExcel } from 'react-export-table-to-excel';

function BookmarkCheckFill() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-bookmark-check-fill bookmark-check-fill" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
        </svg>
    )
}

function UyeTalep() {
    const history = useHistory();

    const [egitimId, setegitimId] = React.useState(0);
    const [guncelle, setGuncelle] = React.useState(false);
    const [detayRow, setDetayRow] = React.useState([]);
    const [detay, setDetay] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [donusTipleri, setDonusTipleri] = React.useState([{ "key": 1, "val": "Memnuniyet" }, { "key": 2, "val": "Talep" }, { "key": 3, "val": "Şikayet(düşük)" }, { "key": 4, "val": "Şikayet(normal)" }, { "key": 5, "val": "Şikayet(yüksek)" }, { "key": 6, "val": "Öneri" }, { "key": 7, "val": "Bilgi" }]);
    const [bildirimDurumlari, setbildirimDurumlari] = React.useState([{ "key": 1, "val": "Geri Bildirim Alındı" }, { "key": 2, "val": "İşlem Devam Ediyor" }, { "key": 3, "val": "İşlem Beklemede" }, { "key": 4, "val": "Yöneticide" }, { "key": 5, "val": "DF Açıldı" }, { "key": 6, "val": "Mutabakat Sağlanamadı" }, { "key": 7, "val": "Dış Kaynaklara Yönlendirildi" }, { "key": 8, "val": "Kapatıldı" }, { "key": 9, "val": "Sonuçlandırıldı" }]);
    const initialForm = { "DonusTipi": "0", "Kisi": "", "Mail": "", "Personel": 0, "OdaSicil": "", "Tarih": "2023-01-01", "Tel": "" }
    const yesilDurumlar = [7, 8, 9];
    const yesilDonusTipDurumlar = [1];
    const [form, setForm] = React.useState(initialForm);
    const [filtre, setFiltre] = React.useState({ "bildirimTipi": 0, "bildirimDurumu": 0, "tad": "GetUyeTalep" });
    const [showModal, setShowModal] = React.useState(false);
    const [atananPersonel, setatananPersonel] = React.useState([]);
    // const [showPersonelModal, setshowPersonelModal] = React.useState(false);
    const tableRef = useRef(null);

    const token = secureLocalStorage.getItem('token');
    // const personelModalAc = (elem) => {
    //     getPersonelData();
    //     getEgitimeKatilanPersonel(elem.Id)
    //     setshowPersonelModal(true);
    //     setForm(elem);
    // }
    // const getPersonelData = () => {
    //     (async () => {
    //         const rawResponse = await fetch(variables.API_URL + 'personel', {
    //             method: 'get',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             },
    //         })
    //             .then(res => res.json())
    //             .then(json => { setpersonelData(json); })
    //             .catch(err => console.log(err));
    //     })();
    // }
    // const getEgitimeKatilanPersonel = (egitimId) => {
    //     (async () => {
    //         const rawResponse = await fetch(variables.API_URL + 'egitim/' + egitimId + "/EgitimeKatilanlar", {
    //             method: 'get',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             },
    //         })
    //             .then(res => res.json())
    //             .then(json => setatananPersonel(json))
    //             .catch(err => console.log(err));
    //     })();
    // }

    const egitimePersonelCikart = (elem) => {
        let tmp = structuredClone(atananPersonel);
        for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            if (elem.k_no === element.k_no) {
                tmp.splice(index, 1);
            }
        }
        setatananPersonel(tmp);

    }
    const notificationAlertRef = React.useRef(null);
    const notify = (place, textt, typee) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {textt}
                    </div>
                </div>
            ),
            type: typee ?? type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    // const getData = async () => {
    //     const istek = await fetch(variables.API_URL + 'page/GetUyeTalep', {
    //         method: 'get',
    //         headers: { 'Tad': 'UyeTalep', 'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    //     })
    //     if (await istek.ok) {
    //         setData(await istek?.json());
    //     } else {
    //         let txt = await istek.text();
    //         alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
    //         history.push("/admin/giris");
    //     }
    // }
    const getDataFiltered = async () => {
        // const ff = structuredClone(filtre);
        // console.log(ff);
        (async () => {
            try {
                const istek = await fetch(variables.API_URL + 'Page/', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Tad': 'GetUyeTalep',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(filtre)
                });
                setData(await istek?.json());

            } catch (error) {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
                history.push("/admin/giris");
            }

            //const content = await rawResponse.json();
        })();
    }
    const addFormData = (key, value) => {
        const ff = structuredClone(form)
        ff[key] = value;
        setForm(ff);
    }
    const talepDetayGetir = (talepId) => {
        (async () => {
            const istek = await fetch(variables.API_URL + 'Page', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Tad': 'GetUyeTalepDetay',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ "uyetalepid": talepId, "tad": "GetUyeTalepDetay" })
            });
            if (await istek.ok) {
                //console.log(await istek?.json());
                setDetay(await istek?.json());
            } else {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            }
        })();
    }
    const duzenle = (elem) => {
        talepDetayGetir(elem.Id);
        setGuncelle(true);
        setShowModal(true);
        setForm(elem);
    }

    const formKaydet = () => {
        // console.log(form, "form");
        const ff = structuredClone(form);
        //ff.Id = 0;
        ff.tad = "InsertUyeTalep";
        (async () => {
            const rawResponse = await fetch(variables.API_URL + 'Page/', {
                method: 'POST',
                // headers: { 'Content-Type': 'application/json' },
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Tad': 'InsertUyeTalep',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(ff)
                //body: ff
            });
            const content = await rawResponse.json();
            // console.log(content);
            modalKapat();
            // getData();
            getDataFiltered();
            notify("tc", "Kayıt işlemi başarılı", "success")
        })();
    }
    const formGuncelle = () => {

        const ff = structuredClone(form);
        ff.tad = "UyeTalep";
        (async () => {
            const rawResponse = await fetch(variables.API_URL + 'page', {
                method: 'put',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Tad': 'UyeTalep',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(ff)
                //body: form
            });
            const content = await rawResponse.json();
            modalKapat();
            //getData();
            getDataFiltered();
            notify("tc", "Güncelleme işlemi başarılı", "success")
        })();
    }
    const formSil = (id) => {
        if (confirm("Silmek istediğinizden emin misiniz?")) {
            // console.log(id, "id");
            //const ff = structuredClone(form);
            const rr ={};
            rr.tad = "UyeTalep";
            rr.Id = id;
            (async () => {
                const rawResponse = await fetch(variables.API_URL + 'Page/', {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Tad': 'UyeTalep',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    //headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(rr)
                    //body: form
                });
                //const content = await rawResponse.json();
                modalKapat();
                //getData();
                getDataFiltered();
                notify("tc", "Silme işlemi başarılı", "success")
            })();
        }
    }
    const detaySil = (id) => {
        if (confirm("Silmek istediğinizden emin misiniz?")) {
            (async () => {
                const rawResponse = await fetch(variables.API_URL + 'Page/', {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Tad': 'UyeTalepDetay',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({ "Id": id, "tad": "UyeTalepDetay" })
                    //body: form
                });
                //const content = await rawResponse.json();
                //modalKapat();
                //getData();
                getDataFiltered();
                notify("tc", "Silme işlemi başarılı", "success")
            })();
        }
    }
    const keyUpSearch = (e, refn) => {
        let filter = e.target.value.toLowerCase();
        if (refn == "scroller1") {
            let nodes = document.getElementsByClassName('scroller1');
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].innerText.toLowerCase().includes(filter)) {
                    nodes[i].style.display = "block";
                } else {
                    nodes[i].style.display = "none";
                }
            }
        }
        if (refn == "scroller2") {
            let nodes = document.getElementsByClassName('scroller2');
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].innerText.toLowerCase().includes(filter)) {
                    nodes[i].style.display = "block";
                } else {
                    nodes[i].style.display = "none";
                }
            }
        }
    }
    const fillDonusTipiSelect = () => {
        var indents = [];
        indents.push(<option key={0} value={0}>SEÇİNİZ</option>);
        for (let i = 0; i < donusTipleri.length; i++) {
            const element = donusTipleri[i];
            indents.push(<option key={element.key} value={element.key}>{element.val}</option>);
        }
        return indents;
    }
    const fillBildirimDurumuSelect = () => {
        var indents = [];
        indents.push(<option key={0} value={0}>SEÇİNİZ</option>);
        for (let i = 0; i < bildirimDurumlari.length; i++) {
            const element = bildirimDurumlari[i];
            indents.push(<option key={element.key} value={element.key}>{element.val}</option>);
        }
        return indents;
    }
    function FormBosalt() {
        //console.log(112)
        setForm(initialForm);
    }
    function personelModalKapat() {
        //setshowPersonelModal(false);
        setForm(initialForm);
        setatananPersonel([]);
        // setGuncelle(false);
    }
    function modalKapat() {
        setShowModal(false);
        setGuncelle(false);
        //setForm({});
    }
    const satirEkle = () => {
        const dt = structuredClone(detayRow);
        dt.UyeTalepId = form.Id;
        dt.tad = "PostUyeTalepDetay";
        //console.log(dt);
        (async () => {
            const istek = await fetch(variables.API_URL + 'Page', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Tad': 'PostUyeTalepDetay',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(dt)
            });
            if (await istek.ok) {
                //console.log(await istek?.json());
                //setDetay(await istek?.json());
                talepDetayGetir(form.Id);
                notify("tc", "Kayıt işlemi başarılı", "success");
            } else {
                let txt = await istek.text();
                alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            }
        })();
    }
    // React.useEffect(() => {
    //     //getData()
    //     getDataFiltered();
    // }, [])
    // React.useEffect(() => {
    //     console.log(`data`, data);
    // }, [data])
    React.useEffect(() => {
        getDataFiltered();
    }, [filtre])


    return (
        <>
            <Container fluid>
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                {/* <Card.Title as="h4">Üye Bildirim</Card.Title> */}
                                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}
                                <Button variant="secondary">Üye Bildirim</Button>
                                <DownloadTableExcel
                                    filename="ÜyeBildiriTablo"
                                    sheet="ÜyeBildirim"
                                    currentTableRef={tableRef.current}
                                >
                                    <Button variant="secondary"> Excel'e aktar </Button>
                                </DownloadTableExcel>
                                <span> {data?.length + " adet kayıt bulundu!"}</span>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Bildirim Tipi:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={filtre?.DonusTipi}
                                                onChange={(e) => { setFiltre({ ...filtre, bildirimTipi: e.target.value }) }}>
                                                {fillDonusTipiSelect()}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Bildirim Durumu:</b></Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={filtre?.bildirimDurumu}
                                                onChange={(e) => { setFiltre({ ...filtre, bildirimDurumu: e.target.value }) }}>
                                                {fillBildirimDurumuSelect()}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="overflow-x-scroll">
                                    {/* <Table ref={tableRef} className="table-hover table-striped uyeTalepTable table-layout-fixed table-td-width-auto"> */}
                                    <Col>
                                    <Table ref={tableRef} className="table-hover table-striped uyeTalepTable table-td-width-auto  ">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Aks.Tarih</th>
                                                <th className="w20percentXXX">Aksiyon</th>
                                                <th>OdaSicil</th>
                                                <th>Görüşülen</th>
                                                <th className="w20percentXXX  ">Açıklama</th>
                                                <th>Tel</th>
                                                <th>Mail</th>
                                                <th>KayıtTarih</th>
                                                <th>Bildirim Tipi</th>
                                                <th>Bildirim Durumu</th>
                                                <th>Personel</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data != undefined > 0
                                                    ? data?.map((elem, index) => {
                                                        return (
                                                            <tr key={index} className={(yesilDurumlar.some(D => D == elem.BildirimDurumu) || yesilDonusTipDurumlar.some(D => D == elem.DonusTipi)) ? "bgGreen" : "bgRed"}>
                                                                <td>{elem.Id}{" "}
                                                                    <span title={"Güncelle/Düzenle"} className="cursor colorGreen">
                                                                        <svg onClick={() => { duzenle(elem) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                        </svg>
                                                                    </span>
                                                                    <span title={"Sil!"} className="cursor colorRed">
                                                                        <svg onClick={() => { formSil(elem.Id) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                                <td>{elem.AksiyonTarih != "" ? elem.AksiyonTarih?.split('T')[0] : "-"}</td>
                                                                <td className=" ">
                                                                    <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-${'left'}`}>{elem.AlinanAksiyon != "" ? elem.AlinanAksiyon : "-"}</Tooltip>}>
                                                                        <div className=" dotdotdot" >{elem.AlinanAksiyon != "" ? elem.AlinanAksiyon : "-"}</div>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td><span title={"Oda Sicil"}>{elem.OdaSicil}</span></td>
                                                                <td><span title={"Görüşülen Kişi"}>{elem.Kisi}</span></td>
                                                                <td>
                                                                    <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-${'left'}`}>{elem.Aciklama}</Tooltip>}>
                                                                        <div className=" dotdotdot" >{elem.Aciklama}</div>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>{elem.Tel}</td>
                                                                <td>{elem.Mail}</td>
                                                                <td>{elem.Tarih?.split('T')[0]}</td>
                                                                <td>{donusTipleri.find(C => C.key == elem.DonusTipi)?.val ?? "-"}</td>
                                                                <td>{bildirimDurumlari.find(C => C.key == elem.BildirimDurumu)?.val ?? "-"}</td>
                                                                <td className="  ">
                                                                    <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-${'left'}`}>{elem.Personel}</Tooltip>}>
                                                                        <div className=" dotdotdot w50px" >{elem.Personel}</div>
                                                                    </OverlayTrigger>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    {
                        showModal ?
                            <Modal size="xl" show={true} backdrop="static" onHide={() => modalKapat()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Bildirim Ekle
                                        <OverlayTrigger key={'top'} placement={'top'}
                                            overlay={<Tooltip id={`tooltip-${'top'}`}>Formu Boşalt</Tooltip>}>
                                            <svg onClick={(e) => FormBosalt()} title="Formu Boşalt" width="22" height="22" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                            </svg>
                                        </OverlayTrigger>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="formUyeTalepEkle" className="was-validated">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Bildirim Tarihi:</Form.Label>
                                            <Form.Control
                                                type={"date"}
                                                required
                                                value={form?.Tarih?.split('T')[0]}
                                                onChange={(e) => addFormData("Tarih", e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Oda Sicil:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={form?.OdaSicil}
                                                onChange={(e) => addFormData("OdaSicil", e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>İletişim Ad Soyad:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={form?.Kisi}
                                                onChange={(e) => addFormData("Kisi", e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Telefon:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={form?.Tel}
                                                onChange={(e) => addFormData("Tel", e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mail:</Form.Label>
                                            <Form.Control
                                                type="mail"
                                                required
                                                value={form?.Mail}
                                                onChange={(e) => addFormData("Mail", e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Geri Bildirim Metni:</Form.Label>
                                            <textarea
                                                className="form-control "
                                                required
                                                value={form?.Aciklama}
                                                onChange={(e) => addFormData("Aciklama", e.target.value)}></textarea>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Bildirim Tipi:</Form.Label>
                                            <Form.Select className="form-control" aria-label="select"
                                                value={form?.DonusTipi}
                                                onChange={(e) => { addFormData("DonusTipi", e.target.value); }}>
                                                {fillDonusTipiSelect()}
                                            </Form.Select>
                                        </Form.Group>
                                        {/* <b>Geri Bildirimin Araştırılması ve Çözümün Sağlanması</b>

                                        <Row className="">
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tarih:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        required
                                                        value={detayRow?.Tarih}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, Tarih: e.target.value }) }}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Durum:</Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={detayRow?.BildirimDurumu}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, BildirimDurumu: e.target.value }) }}>
                                                        {fillBildirimDurumuSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alınan Aksiyon:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        value={detayRow?.AlinanAksiyon}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, AlinanAksiyon: e.target.value }) }} />

                                                   
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Button onClick={satirEkle} variant="btn btn-primary mw100 vh100" >
                                                    +EKLE
                                                </Button>
                                            </Col>
                                        </Row>
                                        <b>ÇÖZÜMÜN GERİ BİLDİRİM SAHİBİNE BİLDİRİLME TARİHİ</b>

                                        <Row className="">
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tarih:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        required
                                                        value={detayRow?.Tarih}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, Tarih: e.target.value }) }}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Durum:</Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={detayRow?.BildirimDurumu}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, BildirimDurumu: e.target.value }) }}>
                                                        {fillBildirimDurumuSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alınan Aksiyon:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        value={detayRow?.AlinanAksiyon}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, AlinanAksiyon: e.target.value }) }} />
 
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Button onClick={satirEkle} variant="btn btn-primary mw100 vh100" >
                                                    +EKLE
                                                </Button>

                                            </Col>
                                        </Row>
                                        <b>GERİ BİLDİRİM SAHİBİ ÇÖZÜM SÜRECİNDEN MEMNUN MU</b>

                                        <Row className="">
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tarih:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        required
                                                        value={detayRow?.Tarih}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, Tarih: e.target.value }) }}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Durum:</Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={detayRow?.BildirimDurumu}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, BildirimDurumu: e.target.value }) }}>
                                                        {fillBildirimDurumuSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alınan Aksiyon:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        value={detayRow?.AlinanAksiyon}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, AlinanAksiyon: e.target.value }) }} />

                                                 
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Button onClick={satirEkle} variant="btn btn-primary mw100 vh100" >
                                                    +EKLE
                                                </Button>

                                            </Col>
                                        </Row>

                                        <b>Geri Bildirim Durumu</b>
                                        <Row className="">
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tarih:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        required
                                                        value={detayRow?.Tarih}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, Tarih: e.target.value }) }}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Durumu:</Form.Label>
                                                    <Form.Select className="form-control" aria-label="select"
                                                        value={detayRow?.BildirimDurumu}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, BildirimDurumu: e.target.value }) }}>
                                                        {fillBildirimDurumuSelect()}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alınan Aksiyon:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        value={detayRow?.AlinanAksiyon}
                                                        onChange={(e) => { setDetayRow({ ...detayRow, AlinanAksiyon: e.target.value }) }} />

                                                   
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Button onClick={satirEkle} variant="btn btn-primary mw100 vh100" >
                                                    +EKLE
                                                </Button>

                                            </Col>
                                        </Row> */}
                                        
                                        
                                        {
                                            detay.length > -1 ?
                                                detay?.map((elem, index) => {
                                                    return (
                                                        <Row key={index} className="border-top border-dark">
                                                            <Col>{elem.Tarih?.split('T')[0]}</Col>
                                                            <Col>{bildirimDurumlari.find(C => C.key == elem.BildirimDurumu)?.val ?? "-"}</Col>
                                                            <Col>{elem.AlinanAksiyon}</Col>
                                                            <Col><button onClick={e => detaySil(elem.Id)} className="btn btn-danger mw100">sil</button></Col>
                                                        </Row>
                                                    )
                                                })
                                                :
                                                <>yükleniyor</>
                                        }

                                    </Form>
                                </Modal.Body>
                                <Row>
                                    <Col>
                                        {
                                            guncelle ?
                                                <Button onClick={formGuncelle} variant="btn btn-primary mw50" >
                                                    Güncelle
                                                </Button>
                                                :
                                                <Button id="formKaydet" onClick={formKaydet} variant="btn btn-primary mw50" >
                                                    Kaydet
                                                </Button>
                                        }
                                        <Button onClick={() => modalKapat()} variant="btn btn-secondary mw50" >
                                            Kapat
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal>
                            :
                            <></>
                    }

                </Row>
            </Container >
        </>
    );
}

export default UyeTalep;
