import React ,{ useState } from "react";
import { createContext } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(["1","2"]);

  const handleToggleTheme = (param) => {
    // const ff = [...theme];
    //ff.push(param)
    //setTheme(ff)
    //console.log(param)
    setTheme([param])
    
  };
const data ={ theme, handleToggleTheme }
  return (
    <ThemeContext.Provider value={data}>
      {children}
    </ThemeContext.Provider>
  );
};