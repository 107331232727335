import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import {
    Button,
    Card,
    Modal, Form,
    Table,
    Container,
    Row, Tooltip,
    Col, Dropdown, OverlayTrigger,
    ListGroup, Spinner, FormControl
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

function Istatistik() {
    const history = useHistory();
    const initialForm = { "AltKategoriAd": "", "AltKategoriId": 0, "Yil": (new Date().getFullYear()), "Deger": 0 };
    const initialFormKategori = { "Birimler": "", "BirimId": "", "KategoriAd": "" };
    const initialFormKategoriAlt = { "UstKategoriAd": "", "UstKategoriId": "", "AltKategoriAd": "" };
    const initialistatistikYillar = { "UstKategoriAd": "", "UstKategoriId": "", "AltKategoriAd": "" };

    const [birimler, setBirimler] = React.useState([]);
    const [kategori, setKategori] = React.useState([]);
    const [altKategori, setAltKategori] = React.useState([]);
    const [istatistikYillar, setistatistikYillar] = React.useState([]);
    const [formKategori, setFormKategori] = React.useState(initialFormKategori);
    const [formKategoriAlt, setFormKategoriAlt] = React.useState(initialFormKategoriAlt);
    const [showModal, setShowModal] = React.useState(false);
    const [showKategoriModal, setShowKategoriModal] = React.useState(false);
    const [showAltKategoriModal, setShowAltKategoriModal] = React.useState(false);

    const [form, setForm] = React.useState(initialForm);

    const token = secureLocalStorage.getItem('token');

    const notificationAlertRef = React.useRef(null);
    const notify = (place, textt, typee) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {textt}
                    </div>
                </div>
            ),
            type: typee ?? type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const addFormData = (key, value) => {
        const ff = structuredClone(form)
        ff[key] = parseInt(value);
        setForm(ff);
        if (key == "Yil") {
            //getIstatistikModal(ff, ff.Yil);
            setTimeout(() => {
                getIstatistikModal(ff.AltKategoriId, ff.Yil, ff.AltKategoriAd);
            }, 500);
        }



    }
    function modalKapat() {
        setShowModal(false);
        setForm(initialForm);
    }
    function getYears() {
        var indents = [];
        for (var i = (new Date().getFullYear()); i > 2008; i--) {
            indents.push(<option key={i} value={i}>{i}</option>);
        }
        return indents;
    }
    const formKategoriAltKaydet = () => {
        let dt = structuredClone(formKategoriAlt);
        (async () => {
            const rawResponse = await fetch(variables.API_URL + 'istatistik/SetKategoriAlt', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(dt)
            });
            const content = await rawResponse;
            if (content.ok) {
                getAltKategoriler(dt);
                setShowAltKategoriModal(false)
                setFormKategoriAlt(initialFormKategoriAlt)
                notify("tc", "Kayıt işlemi başarılı", "success")
            }
        })();
    }
    const formKategoriKaydet = () => {
        let dt = structuredClone(formKategori);
        (async () => {
            const rawResponse = await fetch(variables.API_URL + 'istatistik/SetKategori', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(dt)
            });
            const content = await rawResponse;
            if (content.ok) {
                getKategoriler(dt.BirimId);
                setShowKategoriModal(false)
                notify("tc", "Kayıt işlemi başarılı", "success")
            }
        })();
    }
    const formKaydet = () => {
        let dt = structuredClone(form);
        //dt["AltKategoriId"] = altKategori[0].AltKategoriId;
        (async () => {
            const rawResponse = await fetch(variables.API_URL + 'istatistik', {
                method: 'POST',
                // headers: { 'Content-Type': 'application/json' },
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(dt)
                //body: form
            });
            const content = await rawResponse;
            modalKapat();
            setForm(dt);

            //getData();
            notify("tc", "Kayıt işlemi başarılı", "success")
        })();
    }

    const getBirimler = async () => {
        const istek = await fetch(variables.API_URL + 'istatistik/birimler', {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        if (await istek.ok) {
            setBirimler(await istek?.json());
        } else {
            let txt = await istek.text();
            alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
            history.push("/admin/giris");
        }
    }
    const setAltKategoriler = (elem) => {
        const dt = structuredClone(formKategoriAlt);
        dt.UstKategoriId = elem.KategoriId;
        dt.UstKategoriAd = elem.KategoriAd;
        setFormKategoriAlt(dt);
        //setFormKategoriAlt({ ...formKategoriAlt, UstKategoriId: elem.KategoriId })
        setShowAltKategoriModal(true)
    }
    const setKategoriler = (elem) => {
        const dt = structuredClone(formKategori);
        dt.Birimler = elem.Birimler;
        dt.BirimId = elem.BirimId;
        setFormKategori(dt);
        // setFormKategori({ ...formKategori, BirimId: elem.BirimId })
        setShowKategoriModal(true)
    }
    const getKategoriler = (id) => {
        setKategori([])
        setAltKategori([])
        setShowModal(false)
        setForm(initialForm)
        fetch(variables.API_URL + 'istatistik/kategori/' + id, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((res) => res.json())
            .then(json => {
                setKategori(json);
            })
            .catch(err => {
                console.log(err);
            });

    }
    const getAltKategoriler = (elem) => {
        setAltKategori([])
        setShowModal(false)
        setForm(initialForm)
        const kat = elem.UstKategoriId ?? elem.KategoriId;
        fetch(variables.API_URL + 'istatistik/altKategori/' + kat, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((res) => res.json())
            .then(json => {
                setAltKategori(json);
            })
            .catch(err => {
                console.log(err);
            });

    }
    async function getChartData(AltKategoriId) {
        const res = await fetch(variables.API_URL + 'istatistik/GetChartData/' + AltKategoriId, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((res) => { return res.json() })
            .then(json => {
                setistatistikYillar(json)
            })
            .catch(err => {
                console.log(err);
            });
        return res;
    }

    const getIstatistikModal = (AltKategoriId, yil, AltKategoriAd) => {
        //console.log("elem", elem, form);
        getChartData(AltKategoriId)
        setShowModal(true);
        fetch(variables.API_URL + 'istatistik/Getistatistik/' + yil + '/' + AltKategoriId, {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then((res) => res.json())
            .then(json => {
                let dd = structuredClone(form);
                dd.Deger = 0;
                dd.AltKategoriId = AltKategoriId;
                dd.Yil = yil;
                dd.AltKategoriAd = AltKategoriAd
                for (let index = 0; index < json.length; index++) {
                    dd.Deger = json[index].Deger == undefined ? 0 : json[index].Deger;
                }
                setForm(dd);
            })
            .catch(err => {
                console.log(err);
                //secureLocalStorage.removeItem("token")
            });
    }
    const kategoriSil = (elem) => {
        if (confirm("Eğer bir kategori silmek istiyorsanız ilişkili olan alt kategori ve istatistikleri de silmek zorundasınız. Silinsin mi?")) {
            //alert("Şuan bu yöntem çalışmamaktadır. Bilgi işlem ile görüşün...")
            fetch(variables.API_URL + 'istatistik/DKategori/' + elem.KategoriId, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then((res) => {
                if (res.ok) {
                    // let dt = structuredClone(formKategoriAlt);
                    // console.log(dt)
                    // getAltKategoriler(dt);
                    getKategoriler(elem.BirimId)
                    notify("tc", " Kategori Silindi " + elem.KategoriAd, "success")
                } else {
                    notify("tc", " Kategori Silinemedi!!! " + elem.KategoriAd, "danger")
                }
            })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    const altKategoriSil = (AltKategoriId) => {
        if (confirm("Eğer bir kategori silmek istiyorsanız ilişkili olan alt kategori ve istatistikleri de silmek zorundasınız. Silinsin mi?")) {
            //alert("Şuan bu yöntem çalışmamaktadır. Bilgi işlem ile görüşün...")
            fetch(variables.API_URL + 'istatistik/DAltKategori/' + AltKategoriId, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => {
                    if (res.ok) {
                        let dt = structuredClone(formKategoriAlt);
                        getAltKategoriler(dt);
                        notify("tc", "Silme işlemi başarılı", "success")
                    } else {
                        notify("tc", "Silme işlemi başarısız", "danger")
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    const istatistikSil = (elem) => {
        if (confirm("Silinsin mi?")) {
            fetch(variables.API_URL + 'istatistik/Distatistik/' + elem.Id, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => {
                    if (res.ok) {
                        let dt = structuredClone(formKategoriAlt);
                        getAltKategoriler(dt);
                        notify("tc", " Kategori Silindi " + elem.Yil, "success")
                    } else {
                        notify("tc", " Kategori Silinemedi!!! " + elem.Yil, "danger")
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    React.useEffect(() => {
        getBirimler()
    }, [])
    return (
        <>
            <Container fluid>
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Istatistik</Card.Title>
                                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Row>
                                    <Col>
                                        <b className="text-center">BİRİMLER</b>
                                        {
                                            <ListGroup className="x">
                                                {
                                                    birimler.map((elem, index) => {
                                                        return (
                                                            <ListGroup.Item data-key={elem.BirimId} key={elem.BirimId} className=" p-0" variant="light">
                                                                <OverlayTrigger key={'top'} placement={'top'}
                                                                    overlay={<Tooltip id={`tooltip-${'top'}`}>Kategori Ekle</Tooltip>}>
                                                                    <span className={index + "-birimler badge"} title="Kategori Ekle">
                                                                        <Dropdown >
                                                                            <Dropdown.Toggle className="p-1 m-1" variant="success" id="dropdown-basic">
                                                                                +
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={(e) => { setKategoriler(elem) }}>KATEGORİ EKLE</Dropdown.Item>
                                                                                {/* <Dropdown.Item onClick={(e) => { altKategoriSil(elem.AltKategoriId) }}>SİL</Dropdown.Item> */}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </span>
                                                                </OverlayTrigger>
                                                                <span className="cursor" onClick={(e) => { getKategoriler(elem.BirimId) }}>{" " + elem.Birimler} </span>
                                                            </ListGroup.Item>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        }
                                    </Col>
                                    {
                                        kategori.length > 0 ?
                                            <Col>
                                                <b>KATEGORİLER ({kategori[0].Birimler})</b>
                                                {
                                                    <ListGroup className="x">
                                                        {
                                                            kategori.map((elem, index) => {
                                                                return (
                                                                    <ListGroup.Item data-key={elem.KategoriId} key={elem.KategoriId} className=" p-0" variant="light">
                                                                        <OverlayTrigger key={'top'} placement={'top'}
                                                                            overlay={<Tooltip id={`tooltip-${'top'}`}>Alt Kategori Ekle</Tooltip>}>
                                                                            <span className="badge " title="AltKategori Ekle">
                                                                                <Dropdown className="" >
                                                                                    <Dropdown.Toggle className="p-1 m-1" variant="success" id="dropdown-basic">
                                                                                        +
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={(e) => { setAltKategoriler(elem) }}>ALT KATEGORİ EKLE</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={(e) => { kategoriSil(elem) }}>SİL</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                        <span className="cursor" onClick={(e) => { getAltKategoriler(elem) }}>{elem.KategoriAd} </span>

                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                }
                                            </Col>
                                            : <></>
                                    }
                                    {
                                        altKategori.length > 0 ?
                                            <Col>
                                                <b>ALT KATEGORİLER ({altKategori[0].KategoriAd})</b>
                                                {
                                                    <ListGroup className="x">
                                                        {
                                                            altKategori.map((elem, index) => {
                                                                return (
                                                                    <ListGroup.Item data-key={elem.AltKategoriId} key={elem.AltKategoriId} className="  p-0" variant="light">
                                                                        <OverlayTrigger key={'top'} placement={'top'}
                                                                            overlay={<Tooltip id={`tooltip-${'top'}`}>İstatistik Ekle</Tooltip>}>
                                                                            <span className="badge " >
                                                                                <Dropdown className={elem.AltKategoriId} >
                                                                                    <Dropdown.Toggle className="p-1 m-1" variant="success" id="dropdown-basic">
                                                                                        +
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={(e) => { getIstatistikModal(elem.AltKategoriId, form.Yil, elem.AltKategoriAd) }}>İSTATİSTİK EKLE</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={(e) => { altKategoriSil(elem.AltKategoriId) }}>SİL</Dropdown.Item>
                                                                                    </Dropdown.Menu>

                                                                                </Dropdown>
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                        {/* <span onClick={(e) => { setShowModal(true) }}>{elem.AltKategoriAd} </span> */}
                                                                        <span className={elem.AltKategoriId + " cursor"} onClick={(e) => { getIstatistikModal(elem.AltKategoriId, form.Yil, elem.AltKategoriAd) }}>{elem.AltKategoriAd}</span>
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                }
                                            </Col>
                                            : <></>
                                    }
                                    {
                                        showModal ?
                                            <Col>
                                                <b>İSTATİSTİK ({form?.AltKategoriAd})</b>
                                                <Form id="formEgitimEkle" className="was-validated">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Yıl:</Form.Label>
                                                        <Form.Select className="form-control" aria-label="select"
                                                            value={form?.Yil}
                                                            onChange={(e) => { addFormData("Yil", e.target.value); }}>
                                                            {getYears()}
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>İstatistik:</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            required
                                                            value={form?.Deger}
                                                            onChange={(e) => addFormData("Deger", e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Button id="formKaydet" onClick={formKaydet} variant="btn btn-primary mw50" >
                                                        Kaydet
                                                    </Button>
                                                </Form>
                                                <br />
                                                {
                                                    istatistikYillar.map((kelem, kindex) => {
                                                        return (
                                                            <span key={kindex}>
                                                                <span>
                                                                    <OverlayTrigger key={'top'} placement={'top'}
                                                                        overlay={<Tooltip id={`tooltip-${'top'}`}>İstatistik Sil</Tooltip>}>
                                                                        <button onClick={e => istatistikSil(kelem)} className=" btn btn-danger btn-sm">x</button>
                                                                    </OverlayTrigger>
                                                                    {/* <button className=" btn btn-danger btn-sm">x</button> */}
                                                                    <b> {kelem.Yil}</b>:{kelem.Deger}
                                                                </span>
                                                                <br />
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </Col>
                                            : <></>
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <>
                {
                    false ?
                        <Modal size="xl" show={true} backdrop="static" onHide={() => modalKapat()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Değer Girin</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form id="formEgitimEkle" className="was-validated">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Yıl:</Form.Label>
                                        <Form.Select className="form-control" aria-label="select"
                                            value={form?.Yil}
                                            onChange={(e) => addFormData("Yil", e.target.value)}>
                                            {getYears()}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>İstatistik:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            required
                                            value={form?.Deger}
                                            onChange={(e) => addFormData("Deger", e.target.value)}
                                        />
                                    </Form.Group>

                                </Form>
                            </Modal.Body>
                            <Row>
                                <Col>
                                    <Button id="formKaydet" onClick={formKaydet} variant="btn btn-primary mw50" >
                                        Kaydet
                                    </Button>
                                    <Button onClick={() => modalKapat()} variant="btn btn-secondary mw50" >
                                        Kapat
                                    </Button>
                                </Col>
                            </Row>
                        </Modal>
                        :
                        <></>
                }
                {
                    showKategoriModal ?
                        <Modal size="xl" show={true} backdrop="static" onHide={() => modalKapat()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Ekle</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form id="formKategoriEkle" className="was-validated">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Birim:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            defaultValue={formKategori.Birimler}
                                            disabled
                                        //onChange={(e) => { setFormKategori({ ...formKategori, BirimId: e.target.value });}}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Başlık:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={formKategori.KategoriAd}
                                            onChange={(e) => { setFormKategori({ ...formKategori, KategoriAd: e.target.value.toLocaleUpperCase('TR') }); }}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Row>
                                <Col>
                                    <Button id="formKaydet" onClick={formKategoriKaydet} variant="btn btn-primary mw50" >
                                        Kaydet
                                    </Button>
                                    <Button onClick={() => setShowKategoriModal(false)} variant="btn btn-secondary mw50" >
                                        Kapat
                                    </Button>
                                </Col>
                            </Row>
                        </Modal>
                        :
                        <></>
                }
                {
                    showAltKategoriModal ?
                        <Modal size="xl" show={true} backdrop="static" onHide={() => modalKapat()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Alt Kategori Ekle</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form id="formKategoriEkle" className="was-validated">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Kategori:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            defaultValue={formKategoriAlt.UstKategoriAd}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Başlık:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={formKategoriAlt.AltKategoriAd}
                                            onChange={(e) => { setFormKategoriAlt({ ...formKategoriAlt, AltKategoriAd: e.target.value.toLocaleUpperCase('TR') }); }}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Row>
                                <Col>
                                    <Button id="formKaydet" onClick={formKategoriAltKaydet} variant="btn btn-primary mw50" >
                                        Kaydet
                                    </Button>
                                    <Button onClick={() => setShowAltKategoriModal(false)} variant="btn btn-secondary mw50" >
                                        Kapat
                                    </Button>
                                </Col>
                            </Row>
                        </Modal>
                        :
                        <></>
                }
            </>
        </>
    );
}

export default Istatistik;
